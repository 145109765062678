import React from 'react';
import { Box, Grid } from '@mui/material';
import { ReactComponent as TeamRedLogo } from '../svg/team-red.svg';
import { ReactComponent as TeamBlueLogo } from '../svg/team-blue.svg';

const PageHeader = () => {
  return (
    <div className="pageHeaderRow">
      <Box sx={{ width: '75%', pb:3}}>
        <Grid container alignItems="center" justifyContent="center">
          {/* Team Red SVG */}
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Box
              component={TeamRedLogo}
              sx={{
                mt: 2,
                cursor: 'pointer',
                width: "100%",
                maxWidth: "350px",
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => window.location.href = `/players?team=red`}
            />
          </Grid>

          {/* Logo */}
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              src="/images/logos/fairmont-cup-logo-small.png"
              alt="Fairmont Cup Logo"
              sx={{
                cursor: 'pointer',
                mr:1,
                width: { xs: '50px', sm:'100px', md: '150px' },
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => window.location.href = '/'}
            />
          </Grid>

          {/* Team Blue SVG */}
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Box
              component={TeamBlueLogo}
              sx={{
                mt: 2,
                width: "100%",
                maxWidth: "350px",
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => window.location.href = `/players?team=blue`}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PageHeader;