import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
  styled,
  Box,
} from "@mui/material";
import { StyledFormControlLabel, StyledSwitch } from "../styles/StyledComponents";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { colors } from "../styles/Colors";
import "./LeaderboardTable.css";
import { getTeamMemberNames } from "./Utils";

const convertHeightToString = (heightInInches) => {
  const feet = Math.floor(heightInInches / 12);
  const inches = heightInInches % 12;
  return `${feet}'${inches}"`;
};

function calculateStrokes(handicap, hole) {
  const courseHandicap = Math.round((handicap * hole?.teeBox?.slope / 113) + (hole?.teeBox?.rating - 72));
  
  // Calculate the base strokes. This should be at least zero since players cannot have negative strokes.
  const baseStrokes = Math.max(0, Math.floor(courseHandicap / 18));
  
  // Determine if an extra stroke should be added or subtracted.
  let extraStroke = 0;

  if (courseHandicap >= 0) {
    // Positive or zero course handicap: Extra stroke if the remainder is greater than or equal to the hole rating
    extraStroke = Math.floor(courseHandicap % 18) >= hole?.rating ? 1 : 0;
  } else {
    // Negative course handicap: Reduce strokes for holes with high ratings
    extraStroke = 0;
  }

  return baseStrokes + extraStroke;
}


// Styled components for custom table appearance
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: colors.darkBlue, // Dark blue background
  overflow: "auto",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: "collapse",
  minWidth: "1200px",
  // borderSpacing: "0 1px", // Adds a small gap between rows
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  color: colors.white, // White text
  padding: "8px 16px",
}));

const StickyTableCell = styled(TableCell)(({ index }) => ({
  position: "sticky !important",
  left: index === 0 ? 0 : 0,
  zIndex: 1001,
  border: "none",
  color: colors.white, // White text
  padding: "8px 16px",
  backgroundColor: "inherit",
}));

const StickyTableHeadCell = styled(TableCell)(({ index }) => ({
  position: "sticky !important",
  left: index === 0 ? 0 : 0,
  zIndex: 1002,
  border: "none",
  color: colors.white, // White text
  padding: "8px 16px",
  backgroundColor: colors.darkBlue,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(
  ({ theme, index, numericposition, isfirstonteam, isfourthonteam, teamcolor }) => ({
    backgroundColor: index % 2 === 0 ? colors.scoreCardBlue : colors.darkBlue,
    "&:hover": {
      backgroundColor: numericposition === 1 ? colors.brightGreen : colors.scoreCardHover,
    },
    borderTop: isfirstonteam ? `2px solid ${teamcolor}` : "none",
    borderBottom: isfourthonteam ? `2px solid ${teamcolor}` : "none",
  })
);

const StyledTableHeadCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: colors.darkBlue, // Slightly lighter than the background for the header
  fontWeight: "bold",
}));

const TeamIndicator = styled(Box)(({ color }) => ({
  width: 10,
  height: 10,
  borderRadius: "50%",
  backgroundColor: color,
  display: "inline-block",
  marginRight: 8,
}));

const LeaderboardTable = ({ scorecard, eventName, overall, divideTeams }) => {
  const [showNetScores, setShowNetScores] = useState(divideTeams ? true : false);
  const [orderBy, setOrderBy] = useState("position");
  const [order, setOrder] = useState("asc");

  const rounds = useMemo(() => {
    // Extract the keys from the object
    const keys = Object.keys(scorecard);

    // Sort the keys alphabetically in ascending order
    const sortedKeys = keys.sort((a, b) => a.localeCompare(b));

    // Return the sorted array of keys
    return sortedKeys;
  }, [scorecard]);

  const calculateRoundScores = (scores, handicap) => {
    const playedScores = scores.filter((score) => score.strokeNumber > 0);
    const grossTotalScore = playedScores.reduce((sum, score) => sum + score.strokeNumber, 0);
    const playedHoles = playedScores.length;

    let netTotalScore = 0;
    let scoreToPar = 0;
    let netScoreToPar = 0;
    const scoreTypes = { eagles: 0, birdies: 0, pars: 0, bogeys: 0, doubles: 0, triples: 0 };

    playedScores.forEach((score) => {
      const hole = score.hole;
      const holePar = hole.par;
      const holeRating = hole.rating;

      const holeScoreToPar = score.strokeNumber - holePar;
      scoreToPar += holeScoreToPar;

      let netScore = score.strokeNumber;
      netScore -= calculateStrokes(handicap, hole);
      netTotalScore += netScore;
      const holeNetScoreToPar = netScore - holePar;
      netScoreToPar += holeNetScoreToPar;

      if (score.player.name === "Cole Chelle") {
        console.log("Handicap: ", handicap);
        console.log("Gross score: ", score.strokeNumber);
        console.log("HOLE #: ", score.hole.holeNumber);
        console.log("NET HOLE SCORE: ",netScore);
        console.log("Net TOTAL: ", netTotalScore);
      }

      if (holeScoreToPar <= -2) scoreTypes.eagles++;
      else if (holeScoreToPar === -1) scoreTypes.birdies++;
      else if (holeScoreToPar === 0) scoreTypes.pars++;
      else if (holeScoreToPar === 1) scoreTypes.bogeys++;
      else if (holeScoreToPar === 2) scoreTypes.doubles++;
      else if (holeScoreToPar >= 3) scoreTypes.triples++;
    });

    return {
      grossTotalScore: grossTotalScore,
      netTotalScore: netTotalScore,
      scoreToPar: scoreToPar,
      netScoreToPar: netScoreToPar,
      holesPlayed: playedHoles,
      ...scoreTypes,
    };
  };

  const leaderboardData = useMemo(() => {
    const players = {};
    rounds.forEach((roundKey) => {
      const roundData = scorecard[roundKey];
      Object.values(roundData).forEach((match) => {
        Object.values(match).forEach((player) => {
          const playerTeamName = getTeamMemberNames(match, player.playerInfo.name, player.match);
          const isScramble = player.match?.matchType?.gameFormat === "Scramble";
          let teamCapSet = false;
          if (!(overall && isScramble)) {
            if (!players[playerTeamName]) {
              players[playerTeamName] = {
                name: playerTeamName,
                team: player.playerInfo.team?.color,
                handicap: player.playerInfo.handicap,
                age: player.playerInfo.age,
                height: player.playerInfo.height,
                weight: player.playerInfo.weight,
                rounds: {},
                totalScoreToPar: 0,
                totalNetScoreToPar: 0,
                totalEagles: 0,
                totalBirdies: 0,
                totalPars: 0,
                totalBogeys: 0,
                totalDoubles: 0,
                totalTriples: 0,
              };
            } else {
              if (isScramble) {
                // compute team handicap
                const teamHandicap = (players[playerTeamName].handicap + player.playerInfo.handicap) / 4;
                // update leaderboard with team handicap
                players[playerTeamName].handicap = teamHandicap.toFixed(1);
                teamCapSet = true;
              }
            }
            if (!isScramble) {
              // console.log(`${playerTeamName} with handicap ${players[playerTeamName].handicap}`);
              const roundScores = calculateRoundScores(player.scores, players[playerTeamName].handicap);
              players[playerTeamName].holesPlayed = roundScores.holesPlayed;
              players[playerTeamName].rounds[roundKey] = roundScores;
              players[playerTeamName].totalScoreToPar += roundScores.scoreToPar;
              players[playerTeamName].totalNetScoreToPar += roundScores.netScoreToPar;
              players[playerTeamName].totalEagles += roundScores.eagles;
              players[playerTeamName].totalBirdies += roundScores.birdies;
              players[playerTeamName].totalPars += roundScores.pars;
              players[playerTeamName].totalBogeys += roundScores.bogeys;
              players[playerTeamName].totalDoubles += roundScores.doubles;
              players[playerTeamName].totalTriples += roundScores.triples;
            }
          }
        });
      });
    });

    return Object.values(players);
  }, [scorecard, rounds]);

  const sortedData = useMemo(() => {
    const getScoreToCompare = (player) => (showNetScores ? player.totalNetScoreToPar : player.totalScoreToPar);

    // First, rank the players based on their scores
    const rankedData = [...leaderboardData].sort((a, b) => getScoreToCompare(a) - getScoreToCompare(b));

    let rank = 0;
    let lastScore = null;
    let tieCount = 0;

    const dataWithPositions = rankedData.map((player, index) => {
      const currentScore = getScoreToCompare(player);
      if (currentScore !== lastScore) {
        rank = index + 1;
        tieCount = 0;
      } else {
        tieCount++;
      }
      lastScore = currentScore;

      const position = tieCount > 0 ? `T${rank}` : rank;

      return { ...player, position, numericPosition: rank };
    });

    // Separate players by team and sort within each team
    const redPlayers = dataWithPositions
      .filter((p) => p.team === "Red")
      .sort((a, b) => a.numericPosition - b.numericPosition);
    const bluePlayers = dataWithPositions
      .filter((p) => p.team === "Blue")
      .sort((a, b) => a.numericPosition - b.numericPosition);

    // Add team-specific properties
    const dataWithTeamInfo = dataWithPositions.map((player) => {
      const teamPlayers = player.team === "Red" ? redPlayers : bluePlayers;
      const teamPosition = teamPlayers.indexOf(player) + 1;
      return {
        ...player,
        isFirstOnTeam: teamPosition === 1,
        isFourthOnTeam: teamPosition === 4,
        teamPosition: teamPosition,
      };
    });

    // Define the comparator function for sorting
    const comparator = (a, b) => {
      if (orderBy === "position") {
        if (divideTeams) {
          // If dividing teams, first sort by team, then by team position
          if (a.team !== b.team) {
            return a.team === "Red" ? -1 : 1;
          }
          return a.teamPosition - b.teamPosition;
        }
        return order === "asc" ? a.numericPosition - b.numericPosition : b.numericPosition - a.numericPosition;
      }
      if (b[orderBy] < a[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    };

    return dataWithTeamInfo.sort(comparator);
  }, [leaderboardData, showNetScores, orderBy, order, divideTeams]);

  const handleRequestSort = (property, defaultOrder) => {
    if (order !== defaultOrder || orderBy !== property) {
      setOrder(defaultOrder);
      setOrderBy(property);
    } else {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const headCells = [
    { id: "position", label: "POS" },
    { id: "name", label: "PLAYER" },
    { id: "handicap", label: "HCP" },
    { id: `${showNetScores ? "totalNetScoreToPar" : "totalScoreToPar"}`, label: "TOTAL" },
    { id: "holesPlayed", label: "THRU" },
    ...rounds.map((round) => ({ id: round, label: round.toUpperCase() })),
    // { id: 'round', label: eventName ? abbreviateEventName(eventName) : ''},
    // { id: "age", label: "Age" },
    // { id: "height", label: "Height" },
    // { id: "weight", label: "Weight" },
    { id: "totalEagles", label: "EAGLES" },
    { id: "totalBirdies", label: "BIRDIES" },
    { id: "totalPars", label: "PARS" },
    { id: "totalBogeys", label: "BOGEYS" },
    { id: "totalDoubles", label: "DOUBLES" },
    { id: "totalTriples", label: "TRIPLES+" },
  ];

  function getDefaultSortDirection(headCellId) {
    const descDefaultIds = ["totalEagles", "totalBirdies", "totalPars", "totalBogeys", "totalDoubles", "totalTriples"];

    return descDefaultIds.includes(headCellId) ? "desc" : "asc";
  }

  return (
    <div className={`${eventName === "Overall" ? "leaderBoardContainerOverall" : "leaderBoardContainer"}`}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 700, paddingLeft: 1, letterSpacing: "0.1em", color: colors.white }}>
        LEADERBOARD{eventName === "Overall" ? "" : " - " + eventName?.toUpperCase()}
      </Typography>
      <StyledFormControlLabel
        control={
          <StyledSwitch
            checked={showNetScores}
            onChange={() => setShowNetScores(!showNetScores)}
            name="showNetScores"
            switchcolor={colors.lightGreen}
          />
        }
        label={showNetScores ? "NET SCORES" : "GROSS SCORES"}
      />
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) =>
                index < 2 ? (
                  <StickyTableHeadCell index={index} sx={{ pr: 0 }} key={headCell.id}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : getDefaultSortDirection(headCell.id)}
                      hideSortIcon={orderBy !== headCell.id}
                      onClick={() => handleRequestSort(headCell.id, getDefaultSortDirection(headCell.id))}
                      IconComponent={ArrowDropDownIcon}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          color: `${colors.white} !important`, // Force white color
                        },
                        "&.MuiTableSortLabel-root": {
                          color: colors.white,
                        },
                        "&.Mui-active": {
                          color: colors.white,
                        },
                        "&:hover": {
                          color: colors.white,
                        },
                      }}>
                      {headCell.label}
                    </TableSortLabel>
                  </StickyTableHeadCell>
                ) : (
                  <StyledTableHeadCell sx={{ pr: 0 }} key={headCell.id}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : getDefaultSortDirection(headCell.id)}
                      hideSortIcon={orderBy !== headCell.id}
                      onClick={() => handleRequestSort(headCell.id, getDefaultSortDirection(headCell.id))}
                      IconComponent={ArrowDropDownIcon}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          color: `${colors.white} !important`, // Force white color
                        },
                        "&.MuiTableSortLabel-root": {
                          color: colors.white,
                        },
                        "&.Mui-active": {
                          color: colors.white,
                        },
                        "&:hover": {
                          color: colors.white,
                        },
                      }}>
                      {headCell.label}
                    </TableSortLabel>
                  </StyledTableHeadCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((player, index) => {
              const isFourthOnTeam = divideTeams && showNetScores && orderBy === "position" && player.isFourthOnTeam;
              const isFirstOnTeam = divideTeams && showNetScores && orderBy === "position" && player.isFirstOnTeam;

              {
                /* console.log("LAST PLACE ON TEAM: ", player.name, player.isFirstOnTeam); */
              }

              const teamColor = player.team === "Red" ? colors.red : colors.lightBlue;

              return (
                <StyledTableRow
                  key={player.name}
                  index={index}
                  numericposition={player.numericPosition}
                  isfirstonteam={isFirstOnTeam}
                  isfourthonteam={isFourthOnTeam}
                  teamcolor={teamColor}>
                  <StickyTableCell index={0} sx={{ width: "20px", minWidth: "15px" }}>
                    <Box display="flex" alignItems="center">
                      {player.position}
                      {player.numericPosition === 1 && (
                        <TeamIndicator sx={{ mr: 0, ml: 0.5 }} color={colors.brightGreen} />
                      )}
                    </Box>
                  </StickyTableCell>
                  <StickyTableCell index={1} sx={{ color: "white", minWidth: "110px", width: "125px" }}>
                    <Box display="flex" alignItems="center">
                      <TeamIndicator color={teamColor} />
                      {player.name}
                    </Box>
                  </StickyTableCell>
                  <StyledTableCell>{player.handicap}</StyledTableCell>
                  <StyledTableCell>
                    {showNetScores
                      ? `${player.totalNetScoreToPar > 0 ? "+" : ""}${
                          player.totalNetScoreToPar === 0 ? "E" : player.totalNetScoreToPar
                        }`
                      : `${player.totalScoreToPar > 0 ? "+" : ""}${
                          player.totalScoreToPar === 0 ? "E" : player.totalScoreToPar
                        }`}
                  </StyledTableCell>
                  <StyledTableCell>{player.holesPlayed}</StyledTableCell>
                  {/* <StyledTableCell>
                  {showNetScores 
                    ? `${player.netScore}` 
                    : `${player.grossScore}`
                  }
                </StyledTableCell> */}
                  {rounds.map((round) => (
                    <StyledTableCell key={round}>
                      {player.rounds[round]
                        ? showNetScores
                          ? `${player.rounds[round].netTotalScore}`
                          : `${player.rounds[round].grossTotalScore}`
                        : "-"}
                    </StyledTableCell>
                  ))}
                  {/* <StyledTableCell>{player.age}</StyledTableCell>
                <StyledTableCell>{convertHeightToString(player.height)}</StyledTableCell>
                <StyledTableCell>{player.weight} lbs</StyledTableCell> */}
                  <StyledTableCell>{player.totalEagles}</StyledTableCell>
                  <StyledTableCell>{player.totalBirdies}</StyledTableCell>
                  <StyledTableCell>{player.totalPars}</StyledTableCell>
                  <StyledTableCell>{player.totalBogeys}</StyledTableCell>
                  <StyledTableCell>{player.totalDoubles}</StyledTableCell>
                  <StyledTableCell>{player.totalTriples}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
};

export default LeaderboardTable;
