import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from "../styles/Colors";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  position: 'relative',
  height: '65px',
  display: 'flex',
}));

const ScoreBar = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
});

const Cell = styled(Box)(({ theme, color }) => ({
  flex: 1,
  backgroundColor: color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontWeight: 700,
  fontSize: '1.5rem',
  position: 'relative',
  '&:not(:last-child)': {
    borderRight: '1px solid rgba(255, 255, 255, 0.2)',
  },
}));

const SplitCell = styled(Box)({
  width: '50%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: 700,
  fontSize: '1.5rem',
});

const LabelContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '8px',
  });
  
  const SectionLabel = styled(Typography)({
    flex: 1,
    textAlign: 'center',
    color: colors.white,
    fontSize: '0.75rem',
    fontWeight: 300,
  });

const MatchStatus = ({ teamRed, teamBlue, title }) => {
    const getCellContent = (redTotal, blueTotal, redPoints, bluePoints, isLastRedScore, isLastBlueScore) => {
      if (redPoints === 1 && bluePoints === 1) {
        return {
          color: 'split',
          textRed: isLastRedScore ? redTotal.toString() : '',
          textBlue: isLastBlueScore ? blueTotal.toString() : ''
        };
      }
      if (redPoints === 1) return { color: colors.red, text: isLastRedScore ? redTotal.toString() : '' };
      if (bluePoints === 1) return { color: colors.lightBlue, text: isLastBlueScore ? blueTotal.toString() : '' };
      if (redPoints === 0.5 && bluePoints === 0.5) {
        return {
          color: 'split',
          textRed: isLastRedScore ? redTotal.toString() : '',
          textBlue: isLastBlueScore ? blueTotal.toString() : ''
        };
      }
      return { color: 'transparent', text: '' };
    };
  
    const redCumulative = [
      teamRed.front,
      teamRed.front + teamRed.back,
      teamRed.front + teamRed.back + teamRed.overall
    ];
  
    const blueCumulative = [
      teamBlue.front,
      teamBlue.front + teamBlue.back,
      teamBlue.front + teamBlue.back + teamBlue.overall
    ];
  
    const sections = ['front', 'back', 'overall'];
  
    let lastRedScoreIndex = -1;
    let lastBlueScoreIndex = -1;
  
    // Find the index of the last non-zero score for each team
    for (let i = sections.length - 1; i >= 0; i--) {
      if (lastRedScoreIndex === -1 && teamRed[sections[i]] > 0) {
        lastRedScoreIndex = i;
      }
      if (lastBlueScoreIndex === -1 && teamBlue[sections[i]] > 0) {
        lastBlueScoreIndex = i;
      }
      if (lastRedScoreIndex !== -1 && lastBlueScoreIndex !== -1) break;
    }
  
    const cells = sections.map((section, index) => 
      getCellContent(
        redCumulative[index], 
        blueCumulative[index], 
        teamRed[section], 
        teamBlue[section],
        index === lastRedScoreIndex,
        index === lastBlueScoreIndex
      )
    );
  
    return (
        <Box sx={{ maxWidth: "100%", margin: 'auto', pt: 0, pr: 1, pl: 1, pb: 0 }}>
          {/* <Typography gutterBottom sx={{fontWeight:700, fontSize: '1rem', display:"flex", justifyContent:"center", letterSpacing: "0.1em", color:colors.white, marginBottom:0}}>
            {title.number}
          </Typography>
          <Typography gutterBottom sx={{fontWeight:700, fontSize: '0.75rem', display:"flex", justifyContent:"center", letterSpacing: "0.1em", color:colors.white, marginBottom:1}}>
            {title.text}
          </Typography> */}
          <StyledPaper elevation={3}>
            <ScoreBar>
              {cells.map((cell, index) => (
                <Cell key={index} color={cell.color !== 'split' ? cell.color : 'transparent'}>
                  {cell.color !== 'split' ? (
                    cell.text
                  ) : (
                    <>
                      <SplitCell style={{ backgroundColor: colors.red }}>{cell.textRed}</SplitCell>
                      <SplitCell style={{ backgroundColor: colors.lightBlue }}>{cell.textBlue}</SplitCell>
                    </>
                  )}
                </Cell>
              ))}
            </ScoreBar>
          </StyledPaper>
          <LabelContainer>
            <SectionLabel>FRONT</SectionLabel>
            <SectionLabel>BACK</SectionLabel>
            <SectionLabel>OVERALL</SectionLabel>
          </LabelContainer>
        </Box>
      );
    };
    
    export default MatchStatus;




// import React, { useMemo } from 'react';
// import { Grid, Typography } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import RemoveIcon from '@mui/icons-material/Remove';
// import {StyledBox} from "../styles/StyledComponents";
// import './ScoreCardTable.css';
// import { colors } from "../styles/Colors";

// const calculateNetScore = (score, playerHandicap, holeRating) => {
//   // Assuming a player gets a stroke if their handicap is greater than or equal to the hole rating
//   const strokesGiven = playerHandicap >= holeRating ? 1 : 0;
//   return score - strokesGiven;
// };

// const getTeams = (players) => {
//   return Object.values(players).reduce((teams, player) => {
//     const team = player.playerInfo.team;
//     if (!teams[team]) teams[team] = [];
//     teams[team].push(player);
//     return teams;
//   }, {});
// };

// const calculateBestBall = (players, holes) => {
//   const teams = getTeams(players);
//   const teamScores = {};

//   for (const [teamName, teamPlayers] of Object.entries(teams)) {
//     teamScores[teamName] = holes.map((hole, index) => {
//       const bestScore = Math.min(...teamPlayers.map(player => 
//         calculateNetScore(player.scores[index].strokeNumber, 0, hole.rating) // Using 0 as placeholder for player handicap
//       ));
//       return bestScore;
//     });
//   }

//   return teamScores;
// };

// const MatchStatus = ({ scorecard, holes }) => {
//   const matchStatus = useMemo(() => {
//     const bestBallScores = calculateBestBall(scorecard, holes);
//     const teamNames = Object.keys(bestBallScores).sort((a, b) => b.localeCompare(a)); // Sort in reverse alphabetical order
//     const team1 = teamNames[0];
//     const team2 = teamNames[1];

//     let team1Up = 0;
//     let team2Up = 0;

//     if (teamNames.length < 2) {
//         return {};
//     }

//     for (let i = 0; i < 18; i++) {
//       if (bestBallScores[team1][i] < bestBallScores[team2][i]) {
//         team1Up++;
//       } else if (bestBallScores[team2][i] < bestBallScores[team1][i]) {
//         team2Up++;
//       }
//     }

//     const difference = team1Up - team2Up;
    
//     return {
//       [team1]: difference > 0 ? `${difference} up` : difference < 0 ? `${-difference} down` : 'All Square',
//       [team2]: difference < 0 ? `${-difference} up` : difference > 0 ? `${difference} down` : 'All Square'
//     };
//   }, [scorecard, holes]);

//   const getStatusIcon = (status) => {
//     if (status === 'All Square') return <RemoveIcon fontSize="small" />;
//     return (
//       <ArrowDropDownIcon 
//         fontSize="large" 
//         style={{ 
//           transform: status.includes('up') ? 'rotate(180deg)' : 'rotate(0deg)',
//           transition: 'transform 0.3s ease-in-out',
//           color: 'white',
//           marginLeft: '-4px',  // Adjust this value to fine-tune icon positioning
//           marginRight: '-4px'  // Adjust this value to fine-tune icon positioning
//         }} 
//       />
//     );
//   };

//   return (
//         (Object.entries(matchStatus).length > 0) &&
//         (<Grid className="matchStatusRow" item xs={12}>
//             {Object.entries(matchStatus).map(([team, status]) => (
//             <StyledBox className="matchStatus" team={team}>
//                 <Typography variant="body2" component="h1" sx={{fontWeight:700, letterSpacing: "0.1em", color:colors.white}}>
//                 TEAM {team.toUpperCase()}
//                 </Typography>
//                 <Typography variant="body2" component="div" style={{fontWeight:700, display: 'flex', alignItems: 'center' }}>
//                 {status === 'All Square' ? 'AS' : status.split(' ')[0]}
//                 {getStatusIcon(status)}
//                 </Typography>
//             </StyledBox>
//             ))}
//         </Grid>)
//   );
// };

// export default MatchStatus;