import React, { useContext, useState, useEffect, useRef } from "react";
import {
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  FormControl,
  TableFooter,
  Box,
  Collapse,
} from "@mui/material";
import AuthContext from "../context/AuthContext";
import { Delete } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "./ScoreCardTable.css";
import {
  StyledHeaderFooterColumnCell,
  PlayerNameCell,
  PlayerSelect,
  StyledTableCell,
  StyledButton,
  StyledTableRow,
  PlayerFinalScoreCell,
  AddPlayerCell,
  StyledCheckbox,
  StyledScoreCell,
  StyledMenuItem,
  StyledMenu,
  CustomDialog,
  ScoreCardPlayerRow,
  TeamFinalScoreCell,
} from "../styles/StyledComponents";
import { colors } from "../styles/Colors";
import ScoreModal from "./ScoreModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getTeamMemberNames, abbreviateName } from "./Utils";

function calculateStrokes(handicap, hole) {
  const courseHandicap = Math.round((handicap * hole?.teeBox?.slope / 113) + (hole?.teeBox?.rating - 72));
  
  // Calculate the base strokes. This should be at least zero since players cannot have negative strokes.
  const baseStrokes = Math.max(0, Math.floor(courseHandicap / 18));
  
  // Determine if an extra stroke should be added or subtracted.
  let extraStroke = 0;

  if (courseHandicap >= 0) {
    // Positive or zero course handicap: Extra stroke if the remainder is greater than or equal to the hole rating
    extraStroke = Math.floor(courseHandicap % 18) >= hole?.rating ? 1 : 0;
  } else {
    // Negative course handicap: Reduce strokes for holes with high ratings
    extraStroke = 0;
  }

  return baseStrokes + extraStroke;
}

const ScoreCardTable = ({
  match,
  players,
  availablePlayers,
  rounds,
  holes,
  scoreCard,
  addRoundToMatch,
  removeRoundsFromMatch,
  updateScore,
  saveScores,
  clearScores,
  matchPoints,
}) => {
  const { auth } = useContext(AuthContext);
  const [clearDialogOpen, setClearDialogOpen] = useState(false);
  const [clearTarget, setClearTarget] = useState(null);
  const [clearType, setClearType] = useState("");
  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [saveRequired, setSaveRequired] = useState(null);
  const [cellClick, setCellClick] = useState(null);
  const [keyPressed, setKeyPressed] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scoreModalData, setScoreModalData] = useState({});
  const [showNetScores, setShowNetScores] = useState(false);
  // const [netScoreCard, setNetScoreCard] = useState({});
  const inputRefs = useRef([]); // This will be a 2D array of refs

  const bodyRef = useRef(null);
  const headerRef = useRef(null);

  const handleTouchStart = (e) => {
    // e.preventDefault(); // Prevents the context menu
  };

  const handleFocus = (e) => {
    // e.preventDefault(); // Prevent the default scrolling behavior
    // e.target.select();  // Select the text without scrolling
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectRow = (playerId) => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(playerId)
          ? prevSelected.filter((id) => id !== playerId) // Deselect if already selected
          : [...prevSelected, playerId] // Select if not already selected
    );
  };

  const handleRemoveRounds = () => {
    console.log("SELECTED PLAYERS:", selectedRows);

    if (selectedRows.length > 0) {
      const roundsToRemove = rounds.filter((round) => selectedRows.includes(round.player._id));
      console.log("ATTEMPTING TO REMOVE: ", roundsToRemove);
      setSelectedRows([]);
      removeRoundsFromMatch(roundsToRemove);
    } else {
      alert("No available players to add.");
    }
  };

  const handleAddRound = (playerId) => {
    console.log("AVAILABLE PLAYERS:", availablePlayers);

    if (availablePlayers.length > 0) {
      const roundToAdd = rounds.filter((round) => round.player._id === playerId);
      console.log("ATTEMPTING TO ADD: ", roundToAdd);
      addRoundToMatch(...roundToAdd, match._id);
    } else {
      alert("No players selected to remove");
    }
  };

  const handleScoreChange = (playerId, holeIndex, newScore) => {
    if (newScore === "" || /^\d{0,2}$/.test(newScore)) {
      setSaveRequired(true);
      updateScore(match._id, playerId, holeIndex, newScore);
    }
  };

  const handleSaveScores = async () => {
    try {
      setHighlightedColumn(null);
      setHighlightedRow(null);
      setSaveRequired(false);
      saveScores(match._id);
    } catch (error) {
      alert("Error saving scores:", error.response?.data || error);
      console.error("Error saving scores:", error.response?.data || error);
    }
  };

  const getTeamScoreColor = (teamColor, status) => {
    if (teamColor === "Red" && status === "Won") {
      return colors.darkRed;
    } else if (teamColor === "Blue" && status === "Won") {
      return colors.darkLightBlue;
    } else {
      // You can define a default color here for other cases
      return "inherit"; // Assuming you have a defaultColor defined
    }
  };

  const getTeamColor = (teamColor) => {
    if (teamColor === "Red") {
      return colors.red;
    } else if (teamColor === "Blue") {
      return colors.lightBlue;
    } else {
      // You can define a default color here for other cases
      return "inherit"; // Assuming you have a defaultColor defined
    }
  };

  const TeamTotalRow = ({ team, teamScores }) => {
    const scoreArray = teamScores?.map((scoreObj) => scoreObj.score);

    return (
      team && teamScores &&
      <ScoreCardPlayerRow sx={{ backgroundColor: getTeamColor(team) }}>
        <PlayerNameCell className="playerNameCell" team={team}>
          <Box>{`Team ${team}`}</Box>
        </PlayerNameCell>
        {teamScores.slice(0, 9).map((scoreObj, i) => (
          <StyledScoreCell
            color={getTeamScoreColor(team, scoreObj.status)}
            className={`score-cell ${highlightedColumn === i ? "highlighted" : ""}`}
            onClick={() => handleCellClick(null, i)}
            key={i}>
            <div className={`cell-content`}>{scoreObj.score}</div>
          </StyledScoreCell>
        ))}
        <StyledScoreCell>{calculateSum(scoreArray, 0, 9)}</StyledScoreCell>
        {teamScores.slice(9, 18).map((scoreObj, i) => (
          <StyledScoreCell
            color={getTeamScoreColor(team, scoreObj.status)}
            className={`score-cell ${highlightedColumn === i + 9 ? "highlighted" : ""}`}
            onClick={() => handleCellClick(null, i + 9)}
            key={i + 9}>
            <div className={`cell-content`}>{scoreObj.score}</div>
          </StyledScoreCell>
        ))}
        <StyledScoreCell>{calculateSum(scoreArray, 9, 18)}</StyledScoreCell>
        <TeamFinalScoreCell team={team}>{calculateSum(scoreArray, 0, 18)}</TeamFinalScoreCell>
      </ScoreCardPlayerRow>
    );
  };

  const NetScoreRow = ({ player, team, handicap }) => {
    const netScores = player.scores.map((score) => {
      const strokesGiven = calculateStrokes(handicap, score?.hole);
      return Math.max(score.strokeNumber - strokesGiven, 0);
    });

    return (
      <ScoreCardPlayerRow sx={{ backgroundColor: getTeamColor(team) }}>
        <PlayerNameCell className="playerNameCell" team={team}>
          <Box>Net Score</Box>
        </PlayerNameCell>
        {netScores.slice(0, 9).map((scoreObj, i) => (
          <StyledScoreCell
            color={getTeamColor(team)}
            className={`score-cell ${highlightedColumn === i ? "highlighted" : ""}`}
            onClick={() => handleCellClick(null, i)}
            key={i}>
            <div className={`cell-content`}>{scoreObj === 0 ? "" : scoreObj}</div>
          </StyledScoreCell>
        ))}
        <StyledScoreCell>{calculateSum(netScores, 0, 9) === 0 ? "" : calculateSum(netScores, 0, 9)}</StyledScoreCell>
        {netScores.slice(9, 18).map((scoreObj, i) => (
          <StyledScoreCell
            color={getTeamColor(team)}
            className={`score-cell ${highlightedColumn === i + 9 ? "highlighted" : ""}`}
            onClick={() => handleCellClick(null, i + 9)}
            key={i + 9}>
            <div className={`cell-content`}>{scoreObj === 0 ? "" : scoreObj}</div>
          </StyledScoreCell>
        ))}
        <StyledScoreCell>{calculateSum(netScores, 9, 18) === 0 ? "" : calculateSum(netScores, 9, 18)}</StyledScoreCell>
        <TeamFinalScoreCell team={team}>
          {calculateSum(netScores, 0, 18) === 0 ? "" : calculateSum(netScores, 0, 18)}
        </TeamFinalScoreCell>
      </ScoreCardPlayerRow>
    );
  };

  const ScoreCardBody = ({ scoreCard, holes, auth, selectedRows, handleSelectRow, teamScores, isScramble }) => {
    if (!scoreCard || typeof scoreCard !== "object" || Object.values(scoreCard).length === 0) {
      return null; // or return a message that no data is available
    }

    console.log("Match scorecard: ", scoreCard);
    const players = Object.values(scoreCard).sort((a, b) => {
      const teamComparison = b.round?.team?.color.localeCompare(a.round?.team?.color);
      if (teamComparison !== 0) return teamComparison; // If teams are different, sort by team
      return a.playerInfo?.name.localeCompare(b.playerInfo?.name);
    });
    console.log("PLAYERS: ", players);

    const groupedPlayers = players.reduce((acc, player) => {
      const team = player.round?.team?.color || "Unassigned";
      if (!acc[team]) {
        acc[team] = [];
      }
      acc[team].push(player);
      return acc;
    }, {});
    console.log("GROUPED PLAYERS: ", groupedPlayers);
    let rowIndex = 0;

    const allRows = Object.entries(groupedPlayers).flatMap(([team, teamPlayers]) => {
      const rows = teamPlayers
        .map((player, playerIndex) => {
          // For scramble, only show the first player of each team
          if (isScramble && playerIndex > 0) {
            return null; // Skip additional players in scramble format
          }

          const handicap = isScramble ? teamScores[team].handicap : player.playerInfo.handicap;

          const playerId = player.playerInfo?._id;
          let playerName = player.playerInfo?.name || "";

          if (isScramble) {
            // Abbreviate and combine names for scramble format
            const abbreviatedNames = teamPlayers
              .slice(0, 2) // Limit to first two players
              .map((p) => abbreviateName(p.playerInfo?.name || ""))
              .join(" & ");
            playerName = abbreviatedNames;
          }

          const scores = player.scores || [];

          const grossScoreRow = {
            type: "player",
            content: (
              <ScoreCardPlayerRow
                key={playerId}
                selected={selectedRows.includes(playerId)}
                className={selectedRows.includes(playerId) ? "highlightedRow" : ""}
                sx={{ backgroundColor: rowIndex % 2 === 0 ? colors.darkBlue : colors.scoreCardBlue }}>
                <PlayerNameCell className="playerNameCell" team={team}>
                  {auth && (
                    <StyledCheckbox
                      checked={selectedRows.includes(playerId)}
                      onChange={() => handleSelectRow(playerId)}
                    />
                  )}
                  {playerName}
                </PlayerNameCell>
                {holes?.slice(0, 9).map((hole, i) => (
                  <ScoreCardEntry
                    key={i}
                    holeIndex={i}
                    playerIndex={rowIndex}
                    playerId={playerId}
                    score={scores[i]}
                    hole={hole}
                    isScramble={isScramble}
                    handicap={handicap}
                  />
                ))}
                <StyledScoreCell>
                  {calculateSum(
                    scores.map((score) => score.strokeNumber),
                    0,
                    9
                  )}
                </StyledScoreCell>
                {holes?.slice(9, 18).map((hole, i) => (
                  <ScoreCardEntry
                    key={i + 9}
                    holeIndex={i + 9}
                    playerIndex={rowIndex}
                    playerId={playerId}
                    score={scores[i + 9]}
                    hole={hole}
                    isScramble={isScramble}
                    handicap={handicap}
                  />
                ))}
                <StyledScoreCell>
                  {calculateSum(
                    scores.map((score) => score.strokeNumber),
                    9,
                    18
                  )}
                </StyledScoreCell>
                <PlayerFinalScoreCell team={team}>
                  {calculateSum(
                    scores.map((score) => score.strokeNumber),
                    0,
                    18
                  )}
                </PlayerFinalScoreCell>
              </ScoreCardPlayerRow>
            ),
            rowIndex: rowIndex++,
          };

          // Add net score row for singles matches
          if (match?.matchType.teamFormat === "Singles") {
            const netScoreRow = {
              type: "netScore",
              content: <NetScoreRow key={`${playerId}-net`} player={player} team={team} handicap={handicap} />,
              rowIndex: rowIndex++,
            };

            return [grossScoreRow, netScoreRow];
          }

          return grossScoreRow;
        })
        .flat()
        .filter(Boolean);

      // Add team total row immediately after the team's player rows
      if (match?.matchType.teamFormat !== "Singles") {
        rows.push({
          type: "team",
          content: <TeamTotalRow key={`${team}-total`} team={team} teamScores={teamScores[team]?.scores} />,
          rowIndex: rowIndex++,
        });
      }

      return rows;
    });

    return (
      <TableBody ref={bodyRef}>
        {allRows.map((row) => React.cloneElement(row.content, { key: row.rowIndex }))}
      </TableBody>
    );
  };

  const ScoreCardEntry = ({ holeIndex, playerIndex, playerId, score, hole, handicap }) => {
    return (
      <StyledTableCell
        key={hole._id}
        style={{ maxWidth: "50px", padding: "4px" }}
        className={`score-cell ${highlightedColumn === holeIndex ? "highlighted" : ""} ${
          highlightedRow === playerIndex && highlightedColumn === holeIndex ? "highlightedCell" : ""
        }`}
        onClick={() => {
          handlePlayerCellClick(score, playerIndex, holeIndex);
        }}>
        <Box position="relative" height="50px" display="flex" justifyContent="center" alignItems="center">
          <Box position="absolute" top={0} display="flex" justifyContent="center" alignItems="center" width="100%">
            {calculateStrokes(handicap, hole) > 0 ? (
              Array.from({ length: calculateStrokes(handicap, hole) }).map((_, index) => (
                <FiberManualRecordIcon
                  key={index}
                  style={{
                    fontSize: 5,
                    color: colors.white,
                    marginRight: calculateStrokes(handicap, hole) - 1 !== index ? 2 : 0,
                  }}
                />
              ))
            ) : (
              <></>
            )}
          </Box>
          <div className={`cell-content ${getScoreClass(score.strokeNumber || null, hole.par)}`}>
            {auth ? (
              <input
                type="text"
                onSelect={handleTouchStart}
                onTouchStart={handleTouchStart}
                ref={(el) => {
                  if (!inputRefs.current[playerIndex]) {
                    inputRefs.current[playerIndex] = [];
                  }
                  inputRefs.current[playerIndex][holeIndex] = el;
                }} // Store the input ref in the 2D array
                value={score.strokeNumber || "-"}
                onFocus={handleFocus} // Highlight text on focus
                onChange={(e) => {
                  e.preventDefault();
                  handleScoreChange(playerId, holeIndex, e.target.value);
                }}
                onBlur={(e) => {
                  e.preventDefault();
                  if (e.target.value === "-") {
                    handleScoreChange(playerId, holeIndex, null);
                  }
                }}
                style={{
                  width: "100%",
                  textAlign: "center",
                  border: "none",
                  outline: "none",
                  background: "transparent",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "inherit",
                }}
              />
            ) : (
              score.strokeNumber || "-"
            )}
          </div>
        </Box>
      </StyledTableCell>
    );
  };

  const handleOpenClearDialog = (type, target) => {
    setClearType(type);
    setClearTarget(target);
    setClearDialogOpen(true);
  };

  const handleClearScores = () => {
    setSaveRequired(true);
    clearScores(match._id, clearType, clearTarget);
    setClearDialogOpen(false);
    setHighlightedColumn(null);
    setHighlightedRow(null);
    setSaveRequired(false);
  };

  const handleCloseClearDialog = () => {
    setClearDialogOpen(false);
  };

  const handleCellClick = (row, column) => {
    setCellClick(true);
    setHighlightedColumn(column);
    setHighlightedRow(row);
  };

  const handlePlayerCellClick = (score, row, column) => {
    setCellClick(true);
    setHighlightedColumn(column);
    setHighlightedRow(row);
    const newModalData = {
      scoreInfo: score,
      playerTeamName: getTeamMemberNames(scoreCard, score?.player?.name, match),
      holeIndex: column,
    };
    console.log("MODAL DATA: ", newModalData);
    if (auth) {
      setScoreModalData(newModalData);
      handleOpenModal();
    }
  };

  const checkTableRefs = (event, header, body) => {
    return (
      header.current && !header.current.contains(event.target) && body.current && !body.current.contains(event.target)
    );
  };

  const handleClickOutside = (event) => {
    if (
      checkTableRefs(event, headerRef, bodyRef) &&
      !event.target.closest(".saveButton") &&
      !event.target.closest(".clearButton")
    ) {
      console.log("CLEARING HIGHLIGHTS");
      setHighlightedColumn(null);
      setHighlightedRow(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (highlightedColumn !== null && highlightedRow !== null) {
      setCellClick(false);
      setKeyPressed(false);
      console.log("SELECTING TEXT");
      // Find the input associated with the highlighted column and focus it
      // const input = inputRefs.current[highlightedRow]?.[highlightedColumn];
      // if (input) {
      //   input.focus();
      //   input.select();
      // }
    }
  }, [highlightedColumn, highlightedRow, cellClick, keyPressed]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      setKeyPressed(true);
    };

    // Add event listener to the document to detect key presses
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const calculateSum = (scores, start, end) => {
    return scores?.slice(start, end).reduce((acc, score) => acc + (parseInt(score) || 0), 0);
  };

  const getScoreClass = (score, par) => {
    if (score === null) {
      return "";
    } else {
      const difference = score - par;

      if (difference === -2 || difference === -3) {
        return "eagle";
      } else if (difference === -1) {
        return "birdie";
      } else if (difference === 0) {
        return "par";
      } else if (difference === 1) {
        return "bogey";
      } else if (difference >= 2) {
        return "double-bogey";
      }

      return "";
    }
  };

  /**
   * Converts match results to a dictionary of team scores for Red and Blue teams,
   * with each score accompanied by a win/loss/tie status.
   * @param {Object} matchData - The match data object containing results.
   * @returns {Object} A dictionary with 'Red' and 'Blue' keys and arrays of score objects as values.
   */
  function convertToTeamScoresDictionary(matchData) {
    if (!matchData || !matchData.results || !matchData.results.teamRed || !matchData.results.teamBlue) {
      console.error("Invalid match data structure");
      return {};
    }

    const { teamRed, teamBlue } = matchData.results;
    const teamScores = {
      Red: { scores: [], handicap: teamRed.teamHandicap },
      Blue: { scores: [], handicap: teamBlue.teamHandicap },
    };

    // Ensure both teams have the same number of scores
    const holeCount = Math.min(teamRed.score.length, teamBlue.score.length);

    for (let hole = 0; hole < holeCount; hole++) {
      const redScore = teamRed.score[hole];
      const blueScore = teamBlue.score[hole];

      let redStatus, blueStatus;

      if (redScore < blueScore) {
        redStatus = "Won";
        blueStatus = "Lost";
      } else if (blueScore < redScore) {
        redStatus = "Lost";
        blueStatus = "Won";
      } else {
        redStatus = blueStatus = "Tied";
      }

      teamScores.Red.scores.push({ score: redScore, status: redStatus });
      teamScores.Blue.scores.push({ score: blueScore, status: blueStatus });
    }

    return teamScores;
  }

  const getDialogContent = (clearType, clearTarget, availablePlayers) => {
    return (
      <>
        {`Are you sure you want to clear ${
          clearType === "column"
            ? `Hole ${clearTarget + 1}`
            : clearType === "row"
            ? `row for ${availablePlayers[clearTarget].name}`
            : "the entire scorecard"
        }?`}
      </>
    );
  };

  return (
    <Paper
      className="scoreCardPaper"
      elevation={20}
      sx={{
        paddingTop: 4,
        height: "100%",
        backgroundColor: colors.scoreCardBlue,
        boxShadow: "0px 8px 8px rgba(0, 8, 24, 0.15)",
      }}>
      {/* <StyledFormControlLabel
                control={
                <StyledSwitch
                  checked={showNetScores}
                  onChange={() => setShowNetScores(!showNetScores)}
                  name="showNetScores"
                  switchcolor={colors.lightGreen}
                />
              }
              label={showNetScores ? "NET SCORES" : "GROSS SCORES"}
            /> */}
      <TableContainer className="tableContainer">
        <Table className="scorecard-table">
          <TableHead ref={headerRef}>
            <StyledTableRow>
              <StyledHeaderFooterColumnCell
                sx={{
                  position: "sticky !important",
                  left: 0,
                  zIndex: 1000,
                  backgroundColor: colors.darkBlue, // Ensure it stays on top of other cells
                }}>
                HOLE
              </StyledHeaderFooterColumnCell>
              {Array.from({ length: 9 }, (_, i) => (
                <StyledHeaderFooterColumnCell
                  key={i}
                  className={highlightedColumn === i ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i)}>
                  <div className="cell-content-column-header">
                    {i + 1}
                    {highlightedColumn === i && auth && (
                      <IconButton
                        aria-label="clear column"
                        onClick={() => handleOpenClearDialog("column", i)}
                        size="small"
                        className="delete-icon">
                        <Delete fontSize="12px" />
                      </IconButton>
                    )}
                  </div>
                </StyledHeaderFooterColumnCell>
              ))}
              <StyledHeaderFooterColumnCell>IN</StyledHeaderFooterColumnCell>
              {Array.from({ length: 9 }, (_, i) => (
                <StyledHeaderFooterColumnCell
                  key={i + 9}
                  className={highlightedColumn === i + 9 ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i + 9)}>
                  <div className="cell-content-column-header">
                    {i + 10}
                    {highlightedColumn === i + 9 && auth && (
                      <IconButton
                        aria-label="clear column"
                        onClick={() => handleOpenClearDialog("column", i + 9)}
                        size="small"
                        className="delete-icon">
                        <Delete fontSize="12px" />
                      </IconButton>
                    )}
                  </div>
                </StyledHeaderFooterColumnCell>
              ))}
              <StyledHeaderFooterColumnCell>OUT</StyledHeaderFooterColumnCell>
              <StyledHeaderFooterColumnCell>TOT</StyledHeaderFooterColumnCell>
            </StyledTableRow>
            <TableRow sx={{ backgroundColor: colors.scoreCardBlue }}>
              <StyledTableCell
                sx={{
                  position: "sticky !important",
                  left: 0,
                  zIndex: 1000,
                  backgroundColor: colors.scoreCardBlue, // Ensure it stays on top of other cells
                }}>
                PAR
              </StyledTableCell>
              {holes?.slice(0, 9).map((hole, i) => (
                <StyledTableCell
                  className={highlightedColumn === i ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i)}
                  key={i}>
                  {hole.par}
                </StyledTableCell>
              ))}
              <StyledTableCell>
                {calculateSum(
                  holes.map((hole) => hole.par),
                  0,
                  9
                )}
              </StyledTableCell>
              {holes?.slice(9, 18).map((hole, i) => (
                <StyledTableCell
                  className={highlightedColumn === i + 9 ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i + 9)}
                  key={i + 9}>
                  {hole.par}
                </StyledTableCell>
              ))}
              <StyledTableCell>
                {calculateSum(
                  holes.map((hole) => hole.par),
                  9,
                  18
                )}
              </StyledTableCell>
              <StyledTableCell>
                {calculateSum(
                  holes.map((hole) => hole.par),
                  0,
                  18
                )}
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: colors.darkBlue }}>
              <StyledTableCell
                sx={{
                  position: "sticky !important",
                  left: 0,
                  zIndex: 1000,
                  backgroundColor: colors.darkBlue, // Ensure it stays on top of other cells
                }}>
                YDS
              </StyledTableCell>
              {holes?.slice(0, 9).map((hole, i) => (
                <StyledTableCell
                  className={highlightedColumn === i ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i)}
                  key={i}>
                  {hole.length}
                </StyledTableCell>
              ))}
              <StyledTableCell>
                {calculateSum(
                  holes.map((hole) => hole.length),
                  0,
                  9
                )}
              </StyledTableCell>
              {holes?.slice(9, 18).map((hole, i) => (
                <StyledTableCell
                  className={highlightedColumn === i + 9 ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i + 9)}
                  key={i + 9}>
                  {hole.length}
                </StyledTableCell>
              ))}
              <StyledTableCell>
                {calculateSum(
                  holes.map((hole) => hole.length),
                  9,
                  18
                )}
              </StyledTableCell>
              <StyledTableCell>
                {calculateSum(
                  holes.map((hole) => hole.length),
                  0,
                  18
                )}
              </StyledTableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: colors.scoreCardBlue }}>
              <StyledTableCell
                sx={{
                  position: "sticky !important",
                  left: 0,
                  zIndex: 1000,
                  backgroundColor: colors.scoreCardBlue, // Ensure it stays on top of other cells
                }}>
                HANDICAP
              </StyledTableCell>
              {holes?.slice(0, 9).map((hole, i) => (
                <StyledTableCell
                  className={highlightedColumn === i ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i)}
                  key={i}>
                  {hole.rating}
                </StyledTableCell>
              ))}
              <StyledTableCell></StyledTableCell>
              {holes?.slice(9, 18).map((hole, i) => (
                <StyledTableCell
                  className={highlightedColumn === i + 9 ? "highlighted" : ""}
                  onClick={() => handleCellClick(null, i + 9)}
                  key={i + 9}>
                  {hole.rating}
                </StyledTableCell>
              ))}
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <ScoreCardBody
            scoreCard={scoreCard}
            holes={holes}
            auth={auth}
            selectedRows={selectedRows}
            handleSelectRow={handleSelectRow}
            teamScores={convertToTeamScoresDictionary(matchPoints)}
            isScramble={match?.matchType?.gameFormat === "Scramble"}
          />
          {/* <TableBody ref={bodyRef}>
            {scoreCard &&
              typeof scoreCard === "object" &&
              Object.values(scoreCard).length > 0 &&
              Object.values(scoreCard)
                .sort((a, b) => {
                  const teamComparison = b.round?.team?.color.localeCompare(a.round?.team?.color);
                  if (teamComparison !== 0) return teamComparison; // If teams are different, sort by team
                  return a.playerInfo?.name.localeCompare(b.playerInfo?.name);
                })
                .map((player, playerIndex) => {
                  const team = player.round?.team?.color || "";
                  const playerName = player.playerInfo?.name || "";
                  const playerId = player.playerInfo?._id;
                  const scores = player.scores;
                  return (
                    <ScoreCardPlayerRow
                      key={playerId}
                      selected={selectedRows.includes(playerId)}
                      className={selectedRows.includes(playerId) ? "highlightedRow" : ""}
                      sx={{ backgroundColor: playerIndex % 2 === 0 ? colors.darkBlue : colors.scoreCardBlue }}>
                      <PlayerNameCell className="playerNameCell" team={team}>
                        {auth && (
                          <StyledCheckbox
                            checked={selectedRows.includes(playerId)}
                            onChange={() => handleSelectRow(playerId)}
                          />
                        )}
                        {playerName}
                      </PlayerNameCell>
                      {holes?.slice(0, 9).map((hole, i) => {
                        return (
                          <ScoreCardEntry
                            holeIndex={i}
                            playerIndex={playerIndex}
                            playerId={playerId}
                            score={scores[i]}
                            hole={hole}
                          />
                        );
                      })}
                      <StyledScoreCell>
                        {calculateSum(
                          scores.map((score) => score.strokeNumber),
                          0,
                          9
                        )}
                      </StyledScoreCell>
                      {holes?.slice(9, 18).map((hole, i) => {
                        return (
                          <ScoreCardEntry
                            holeIndex={i + 9}
                            playerIndex={playerIndex}
                            playerId={playerId}
                            score={scores[i + 9]}
                            hole={hole}
                          />
                        );
                      })}
                      <StyledScoreCell>
                        {calculateSum(
                          scores.map((score) => score.strokeNumber),
                          9,
                          18
                        )}
                      </StyledScoreCell>
                      <PlayerFinalScoreCell team={team}>
                        {calculateSum(
                          scores.map((score) => score.strokeNumber),
                          0,
                          18
                        )}
                      </PlayerFinalScoreCell>
                    </ScoreCardPlayerRow>
                  );
                })}
          </TableBody> */}
          <TableFooter>
            {auth && (availablePlayers > 0 || Object.values(scoreCard)?.length < match?.matchType?.maxPlayers) && (
              <ScoreCardPlayerRow>
                <FormControl
                  fullWidth
                  sx={{
                    position: "sticky !important",
                    left: 0,
                    zIndex: 1000,
                  }}>
                  <AddPlayerCell cellcolor={colors.brightGreen}>
                    <PlayerSelect
                      fullWidth
                      value={""}
                      onChange={(e) => {
                        console.log(e);
                        // update scorecard with new player
                        handleAddRound(e.target.value);
                      }}
                      displayEmpty
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,
                          sx: {
                            borderRadius: "2px",
                            border: `2px solid ${colors.brightGreen} !important`,
                            boxShadow: `inset 0 0 10px ${colors.brightGreen} !important`,
                            transition: "box-shadow 0.3s ease-in-out",
                            "&:hover": {
                              boxShadow: `inset 0 0 10px ${colors.brightGreen}`,
                            },
                          },
                        },
                      }}>
                      <StyledMenu bordercolor={colors.brightGreen} value="" dense>
                        + Add Player
                      </StyledMenu>
                      {availablePlayers.map((player) => (
                        <StyledMenuItem
                          bgcolor={colors.darkBlue}
                          textcolor={colors.white}
                          hovercolor={colors.scoreCardHover}
                          key={player._id}
                          value={player._id}
                          dense={true}>
                          {player.name}
                        </StyledMenuItem>
                      ))}
                    </PlayerSelect>
                  </AddPlayerCell>
                </FormControl>
                <StyledTableCell colSpan={holes.length + 3}>{""}</StyledTableCell>
              </ScoreCardPlayerRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {auth && isModalOpen && scoreModalData.scoreInfo && (
        <ScoreModal
          open={auth && isModalOpen}
          onClose={handleCloseModal}
          scoreInfo={scoreModalData?.scoreInfo}
          playerTeamName={scoreModalData?.playerTeamName}
          holeIndex={scoreModalData?.holeIndex}
          onScoreSelect={handleScoreChange}
        />
      )}
      <div className="scorecardButtonRow">
        <div className="buttonGroup">
          {auth && selectedRows.length > 0 && (
            <StyledButton
              className="removeSelectedButton"
              buttoncolor={colors.brightGreen}
              onClick={handleRemoveRounds}>
              Remove Selected
            </StyledButton>
          )}
        </div>
        <div className="buttonGroup">
          {auth && Object.values(scoreCard).length > 0 && (
            <StyledButton
              className="clearButton"
              buttoncolor={colors.red}
              onClick={() => handleOpenClearDialog("all", null)}>
              Clear Scores
            </StyledButton>
          )}
          {auth && saveRequired && (
            <StyledButton className="saveButton" buttoncolor={colors.brightGreen} onClick={handleSaveScores}>
              Save Scores
            </StyledButton>
          )}
        </div>
      </div>
      <CustomDialog
        open={clearDialogOpen}
        onClose={handleCloseClearDialog}
        title="Confirm Clear"
        onConfirm={handleClearScores}
        children={getDialogContent(clearType, clearTarget, availablePlayers)}></CustomDialog>
    </Paper>
  );
};

export default ScoreCardTable;
