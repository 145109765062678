import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "https://www.fairmontcup.com/api";

console.log("TRYING TO FIND BACKEND AT: ", apiUrl);

// Create an instance of axios
const apiClient = axios.create({
  baseURL: apiUrl, // Replace with your base URL
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000, // Optional: set a timeout for requests
});

export const postLogin = async (username, password) => {
  try {
    const response = await apiClient.post("/login", { username, password });
    return response.data;
  } catch (error) {
    console.error("Login failed", error);
  }
};

export const fetchHoles = async (courseId) => {
  try {
    const response = await apiClient.get(`/holes/course/${courseId}`);
    console.log("HOLES: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving course", error);
  }
};

export const fetchPlayers = async (tournamentId) => {
  try {
    const response = await apiClient.get(`/players/tournament/${tournamentId}`);
    console.log("PLAYERS: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving players", error);
  }
};

export const fetchTournaments = async () => {
  try {
    const response = await apiClient.get("/tournaments");
    console.log("TOURNAMENTS: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving tournaments", error);
  }
};

export const fetchTournamentPlayers = async (tournamentId) => {
  try {
    const response = await apiClient.get(`/tournamentplayers/tournament/${tournamentId}`);
    console.log("TOURNAMENT PLAYERS: ", response.data);
    return response.data;
  } catch (error) {
    console.log("TOURNAMENT PLAYERS ERROR: ", error);
    console.error("Error retrieving events", error);
  }
};

export const fetchTournamentPoints = async (tournamentId) => {
  try {
    const response = await apiClient.get(`/tournaments/${tournamentId}/points`);
    console.log("TOURNAMENT STATUS: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving matches", error);
  }
};

export const fetchEvents = async (tournamentId) => {
  try {
    const response = await apiClient.get(`/events/tournament/${tournamentId}`);
    const sortedEvents = response.data.sort((a, b) => a.name.localeCompare(b.name)); // Sort events alphabetically
    console.log("EVENTS: ", sortedEvents);
    return sortedEvents;
  } catch (error) {
    console.error("Error retrieving events", error);
  }
};

export const fetchMatches = async (eventId) => {
  try {
    const response = await apiClient.get(`/matches/event/${eventId}`);
    const sortedMatches = response.data.sort((a, b) => a.name.localeCompare(b.name)); // Sort matches alphabetically
    console.log("MATCHES: ", sortedMatches);
    return sortedMatches;
  } catch (error) {
    console.error("Error retrieving matches", error);
  }
};

export const fetchMatchPoints = async (matchId) => {
  try {
    const response = await apiClient.get(`/matches/match/${matchId}/points`);
    console.log("MATCH STATUS: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving match points", error);
  }
};

export const fetchEventPoints = async (eventId) => {
  try {
    const response = await apiClient.get(`/events/event/${eventId}/points`);
    console.log("EVENT STATUS: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving event points", error);
  }
};

export const fetchRounds = async (eventId) => {
  try {
    const response = await apiClient.get(`/rounds/event/${eventId}`);
    console.log("ROUNDS: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error retrieving rounds", error);
  }
};

export const fetchScoresByEvent = async (eventId) => {
  try {
    console.log("fetching scores for event");
    const response = await apiClient.get(`/scores/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("Error retrieving scores", error);
    return [];
  }
};

export const fetchScoresByTournament = async (tournamentId) => {
  try {
    console.log("fetching scores for event");
    const response = await apiClient.get(`/scores/tournament/${tournamentId}`);
    return response.data;
  } catch (error) {
    console.error("Error retrieving scores", error);
    return [];
  }
};

export const postScores = async (scores, eventId, auth) => {
  try {
    console.log("Saving scores for event", scores);
    const response = await apiClient.post(
      "/scores/save",
      { scores: scores, eventId: eventId },
      {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving scores", error);
  }
};

export const postRounds = async (upsertedRounds, eventId, auth) => {
  try {
    const response = await apiClient.post(
      "/rounds/upsert",
      { rounds: upsertedRounds, eventId: eventId },
      {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }
    );
    console.log("upserting rounds for event, response is: ", response.data);
    return response.data;
  } catch (error) {
    console.error("Error upserting rounds", error);
  }
};
