import React, { useState, useContext } from 'react';
import { 
  Modal, 
  Box, 
  Typography, 
  Grid, 
  Snackbar
} from '@mui/material';
import { StyledText, StyledButton} from "../styles/StyledComponents";
import AuthContext from '../context/AuthContext';
import { colors } from "../styles/Colors";
import './ScoreCardTable.css';

const LoginModal = ({ open, onClose }) => {
  const { auth, login, logout} = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (auth) {
      logout();
      onClose();
    } else {
      handleLogin(e);
      onClose();
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');
    try {
      await login(username, password);
    } catch (error) {
      setLoginError('Login failed. Please check your credentials.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="login-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: '75%', md: '50%' }, // Responsive widths
        maxWidth: '400px',
        bgcolor: colors.darkBlue,
        borderRadius: 5,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 8px 8px rgba(0, 8, 24, 0.15)',
      }}>
        <Typography 
          id="login-modal-title" 
          variant="h4" 
          component="h1" 
          gutterBottom 
          sx={{ fontWeight: 500, letterSpacing: "0.1em", color: colors.white }}
        >
          {auth ? 'Logout' : 'Login'}
        </Typography>

        {!auth ? (
          <form className="loginContainer" onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledText
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <StyledText
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  margin="normal"

                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
                <StyledButton
                  className="cancelButton"
                  buttoncolor={colors.red}
                  variant="contained"
                  onClick={onClose}
                  fullWidth
                  sx={{
                    mr: 2,
                    backgroundColor: colors.red,
                    color: colors.white,
                    '&:hover': {
                      backgroundColor: colors.red,
                    }
                  }}
                >
                  Cancel
                </StyledButton>
                <StyledButton 
                  className="loginButton" 
                  buttoncolor={colors.brightGreen} 
                  variant="contained" 
                  type="submit"
                  fullWidth
                  sx={{ 
                    ml: 2, 
                    backgroundColor: colors.brightGreen,
                    color: colors.white,
                    '&:hover': {
                      backgroundColor: colors.darkGreen,
                    }
                  }}
                >
                  Login
                </StyledButton>
              </Grid>
              {loginError && (
                <Grid item xs={12}>
                  <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                    {loginError}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </form>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <StyledButton
                onClick={onClose}
                variant="contained"
                buttoncolor={colors.red}
                fullWidth
                sx={{
                  mt: 2,
                }}
              >
                Cancel
              </StyledButton>
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                onClick={handleSubmit}
                variant="contained"
                buttoncolor={colors.brightGreen}
                fullWidth
                sx={{
                  mt: 2,
                }}
              >
                Logout
              </StyledButton>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default LoginModal;

