import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid,Typography, ToggleButtonGroup, Box} from '@mui/material';
import {
  StyledToggleButton,
} from "../styles/StyledComponents";
import { fetchPlayers, fetchTournaments,fetchScoresByTournament, fetchTournamentPlayers,} from '../services/api';
import AuthContext from '../context/AuthContext';
import './PlayerPage.css'; // Import the CSS file
import { colors } from "../styles/Colors";
import PlayerProfileCard from '../components/PlayerProfileCard';
import PageHeader from '../components/PageHeader';
import PlayerGrid from '../components/PlayerGrid';


const PlayerPage = () => {
  const location = useLocation();
  const { auth, login } = useContext(AuthContext);
  const [leaderBoard, setLeaderBoard] = useState({})
  const [players, setPlayers] = useState([]);
  const [tournamentPlayers, setTournamentPlayers] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [selectedTeam, setSelectedTeam] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialTeam = queryParams.get('team');
    if (initialTeam) {
      setSelectedTeam(initialTeam.charAt(0).toUpperCase() + initialTeam.slice(1));
    }
  }, [location.search]);

  const loadPlayers = async (tournamentId) => {
    try {
      const playerData = await fetchPlayers(tournamentId);
      setPlayers(playerData);
    } catch (error) {
      console.error('Error retrieving players', error);
    }
  };

  const loadTournaments = async () => {
    try {
      const tournamentData = await fetchTournaments();
      setTournaments(tournamentData);
    } catch (error) {
      console.error('Error retrieving tournaments', error);
    }
  };

  const loadTournamentPlayers = async (tournamentId) => {
    try {
      const tournamentPlayersData = await fetchTournamentPlayers(tournamentId);
      setTournamentPlayers(tournamentPlayersData);
    } catch (error) {
      console.log("TOURNAMENT PLAYERS ERROR: ", error);
      console.error('Error retrieving tournament players', error);
    }
  };

  const handleTeamChange = async (event, newTeam) => {

    if (newTeam !== null) {
      setSelectedTeam(newTeam);
    }
  };


//   const loadScoresByTournament = async (tournamentId) => {
//     try {
//         console.log("fetching scores for tournament");
//         const scoreByTournamentData = await fetchScoresByTournament(tournamentId);
//         return scoreByTournamentData
//     } catch (error) {
//         console.error('Error retrieving scores', error);
//         return [];
//     }
//   };

//   function groupScoresByEventId(scores, eventIds) {
//     const groupedScores = {};
  
//     scores.forEach((score) => {
//       try {
//         const eventId = score.match.event;
    
//         if (eventIds.includes(eventId)) {
//           // If the eventId is already a key, push the score into the existing array
//           if (!groupedScores[eventId]) {
//             groupedScores[eventId] = [];
//           }
    
//           groupedScores[eventId].push(score);
//         }
//       } catch (error) {

//       }
//     });
//     console.log("GROUPED SCORES: ", groupedScores);
//     return groupedScores;
//   }

//   const loadLeaderboard = async () => {

//     const scores = await loadScoresByTournament(tournaments[0]._id);
//     console.log("LEADERBOARD SCORES; ", scores);
//     setLeaderBoard(createLeaderboard(scores));
//     setIsLoading(false);
//   };

//   const createLeaderboard = (scores) => {
//     const leaderScoreCards = {}

//     // organize scores by event
//     console.log("LEADER SCORES: ", scores)
//     const filteredEvents = events.filter(event => event.name !== "Overall")
//     const eventIds = filteredEvents.map(event => event._id);
//     console.log("EVENT IDS: ", eventIds);
//     const scoresByEvent = groupScoresByEventId(scores,eventIds);
//     console.log("SCORES BY EVENT: ", scoresByEvent);
//     // Transform each array of scores into a scorecard object using createScoreCard
//     filteredEvents.forEach( event => {
//       console.log("MAKING SCORECARD FOR: ", event._id);
//       const eventScoreCard = createScoreCard(scoresByEvent[event._id]);
//       console.log("EVENT SCORECARD: ", eventScoreCard);
//       leaderScoreCards[event.name] = eventScoreCard;
//     });
//     console.log("LEADERBOARD - OVERALL: ", leaderScoreCards);
//     return leaderScoreCards
//   };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    loadTournaments();
  }, []);

  useEffect(() => {
    if (tournaments.length > 0) {
      loadPlayers(tournaments[0]._id);
      loadTournamentPlayers(tournaments[0]._id);
    }
  }, [tournaments]);

  return (
    <div className='playerPage'>
      <Grid className="gridContainer" container flexDirection="row" rowSpacing={0}>
        <Grid item xs={12} >
          <PageHeader/>
        </Grid>
        <Grid item xs={12} sx={{mb:2}}>
          <ToggleButtonGroup
            value={selectedTeam}
            exclusive
            onChange={handleTeamChange}
            aria-label="teamview selection"
            fullWidth
            elevation={20} sx={{ boxShadow: '0px 8px 8px rgba(0, 8, 24, 0.15)'}}
          >
            <StyledToggleButton selectedcolor={colors.red} hovercolor={colors.hoverRed} textcolor={colors.white} key='teamRed' value={"Red"}>
              TEAM RED
            </StyledToggleButton>
            <StyledToggleButton selectedcolor={colors.lightBlue} hovercolor={colors.hoverlightBlue} textcolor={colors.white} key='teamBlue' value={"Blue"}>
              TEAM BLUE
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sx={{mb:1, mt:1}} >
          <Typography variant="h5" gutterBottom sx={{fontWeight:700, paddingLeft:1, letterSpacing: "0.1em", color:colors.white}}>
              ROSTER
          </Typography>
        </Grid>
          <Grid item xs={12} >
          <PlayerGrid players={players} teamColor={selectedTeam}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default PlayerPage;