// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-banner {
    position: relative;
    width: 100%;
    z-index: 1; /* Ensure the content is above the background image */
  }`, "",{"version":3,"sources":["webpack://./src/components/CourseBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU,EAAE,qDAAqD;EACnE","sourcesContent":[".course-banner {\n    position: relative;\n    width: 100%;\n    z-index: 1; /* Ensure the content is above the background image */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
