import { styled } from "@mui/system";
import {
  TableCell,
  TableRow,
  TextField,
  Select,
  ToggleButton,
  Typography,
  Button,
  Checkbox,
  MenuItem,
  Menu,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { colors } from "./Colors"; // Adjust the import according to your file structure
import { darken, alpha } from "@mui/material/styles";

const MIN_TABLE_CELL_WIDTH = "100px"; // Adjust this value according to your requirement

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: colors.hoverlightGreen, // Default color when unchecked
  "& .MuiSvgIcon-root": {
    fontSize: 18,
  },
  "&.Mui-checked": {
    color: colors.brightGreen,
    backgroundColor: colors.white,
  }, // Color when checked
  "&.MuiCheckbox-root": {
    padding: 0,
  },
  "& svg": {
    scale: "1.35",
  },
}));

export const PlayerSelect = styled(Select)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "flex-start",
  color: "#FFFFFF",
  fontSize: 12,
  fontWeight: 700,
  minWidth: "50px",
  textAlign: "left",
  borderBottom: 0,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove the default border
  },
  "& .MuiSelect-icon": {
    color: "#FFFFFF", // Ensure the dropdown icon is also white
  },
}));

export const PlayerFinalScoreCell = styled(TableCell)(({ theme, team }) => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontWeight: 700,
  position: "relative",
  textAlign: "center",
  width: "25px",
  border: team === "Red" ? `1px solid ${colors.red}` : `1px solid ${colors.lightBlue}`,
  boxShadow: team === "Red" ? `inset 0 0 10px  ${colors.red}` : `inset 0 0 10px  ${colors.lightBlue}`,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: team === "Red" ? `inset 0 0 20px  ${colors.red}` : `inset 0 0 20px  ${colors.lightBlue}`,
  },
}));

export const TeamFinalScoreCell = styled(TableCell)(({ theme, team }) => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontWeight: 700,
  position: "relative",
  textAlign: "center",
  width: "25px",
  border: team === "Red" ? `1px solid ${colors.red}` : `1px solid ${colors.lightBlue}`,
}));

export const AddPlayerCell = styled(TableCell)(({ cellcolor }) => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontWeight: 700,
  minWidth: "100px",
  borderBottom: 0,
  position: "sticky !important",
  left: 0,
  padding: 0,
  zIndex: 999,
  // backgroundColor: cellcolor,
  // boxShadow: `0 0 10px  ${cellcolor}`,
  // transition: 'box-shadow 0.3s ease-in-out',
  // '&:hover': {
  //   boxShadow: `0 0 20px  ${cellcolor}`,
  // }
  backgroundColor: "inherit",
  border: `1px solid ${cellcolor}`,
  borderLeft: `5px solid ${cellcolor}`,
  boxShadow: `inset 10px 0 10px -10px  ${cellcolor}`,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: `inset 15px 0 15px -10px   ${cellcolor}`,
  },
}));

export const PlayerNameCell = styled(TableCell)(({ team }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "1rem",
  color: "#FFFFFF",
  fontSize: 14,
  fontWeight: 700,
  minWidth: "75px",
  position: "sticky !important",
  left: 0,
  zIndex: 999,
  justifyContent: "flex-start",
  textAlign: "left",
  borderBottom: 0,
  height: "35px",
  backgroundColor: "inherit",
  borderLeft: team === "Red" ? `5px solid ${colors.red}` : `5px solid ${colors.lightBlue}`,
  boxShadow: team === "Red" ? `inset 10px 0 10px -10px  ${colors.red}` : `inset 10px 0 10px -10px  ${colors.lightBlue}`,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow:
      team === "Red" ? `inset 15px 0 15px -10px   ${colors.red}` : `inset 15px 0 15px -10px   ${colors.lightBlue}`,
  },
}));

// Styled Header/Footer Table Cell
export const StyledHeaderFooterColumnCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: colors.darkBlue,
  color: colors.white,
  fontSize: 14,
  fontWeight: 900,
  textAlign: "center",
  borderBottom: 0,
  padding: 5,
  width: "35px",
  "&.MuiTableCell-head": {
    backgroundColor: colors.darkBlue,
    color: colors.white,
  },
  "&.MuiTableCell-body": {
    backgroundColor: colors.darkBlue,
    color: colors.white,
  },
  "&.MuiTableCell-footer": {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    borderBottom: 0,
  },
}));

export const StyledHeaderFooterRowCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: colors.darkBlue,
  color: colors.white,
  fontSize: 14,
  fontWeight: 700,
  textAlign: "center",
  minWidth: MIN_TABLE_CELL_WIDTH,
  padding: 5,
  borderBottom: 0,
  "&.MuiTableCell-head": {
    backgroundColor: colors.darkBlue,
    color: colors.white,
  },
  "&.MuiTableCell-body": {
    backgroundColor: colors.darkBlue,
    color: colors.white,
  },
  "&.MuiTableCell-footer": {
    backgroundColor: colors.darkBlue,
    color: colors.white,
    borderBottom: 0,
  },
}));

// Styled SCore Cell
export const StyledScoreCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: "0.1em",
  color: "#FFFFFF",
  textAlign: "center",
  borderBottom: 0,
  padding: 16,
}));

// Styled Table Cell
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 300,
  letterSpacing: "0.1em",
  color: colors.white,
  textAlign: "center",
  borderBottom: 0,
  padding: 8,
}));

// Prediction Table Cell
export const PredictionTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: colors.brightGreen,
  color: colors.white,
  fontSize: 13,
  fontWeight: 500,
  borderBottom: 0,
  minWidth: MIN_TABLE_CELL_WIDTH,
}));

// Styled Table Row
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  maxHeight: "30px",
  height: "30px",
  "&:hover": {
    backgroundColor: colors.hoverbrightGreen,
  },
}));

export const ScoreCardPlayerRow = styled(TableRow)(({ theme }) => ({
  maxHeight: "40px",
  height: "40px",
}));

export const StyledText = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: colors.white, // Text color
    backgroundColor: colors.darkBlue, // Background color for input
    padding: "12px", // Add padding if needed
  },
  "& .MuiInputLabel-root": {
    color: colors.white, // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: colors.brightGreen, // Label color when focused
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colors.lightGreen, // Underline color for text fields that are not outlined
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: colors.darkBlue, // Background color for outlined input
    "& fieldset": {
      borderColor: colors.lightGreen, // Border color for outlined input
    },
    "&:hover fieldset": {
      borderColor: colors.brightGreen, // Border color on hover for outlined input
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.brightGreen, // Border color when focused for outlined input
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: colors.lightGreen, // Text color for disabled input
    },
  },
  // Autofill styles
  "& input:-webkit-autofill": {
    "-webkit-box-shadow": `0 0 0 100px ${colors.darkBlue} inset`,
    "-webkit-text-fill-color": colors.white,
  },
  "& input:-webkit-autofill::first-line": {
    fontSize: "1rem", // Maintain consistent font size
  },
}));

// Styled Toggle Button
export const StyledToggleButton = styled(ToggleButton)(({ theme, selectedcolor, hovercolor, textcolor }) => ({
  fontSize: 9,
  padding: 10,
  borderRadius: 0,
  backgroundColor: colors.darkBlue,
  transition: "box-shadow 0.3s ease-in-out",
  color: `${textcolor} !important`,
  "&.Mui-selected": {
    backgroundColor: colors.darkBlue,
    border: `2px solid ${selectedcolor}`,
    boxShadow: `inset 0 0 5px ${selectedcolor}`,
    "&:hover": {
      boxShadow: `inset 0 0 10px ${selectedcolor}`,
    },
  },
  "&:hover": {
    border: `2px solid ${selectedcolor}`,
    boxShadow: `inset 0 0 10px ${selectedcolor}`,
  },
}));

// Header Text
export const HeaderText = styled(Typography)(({ theme }) => ({
  color: colors.white,
  fontWeight: 900,
}));

// Player Info Text
export const PlayerInfoText = styled(Typography)(({ theme }) => ({
  color: colors.darkBlue,
  fontWeight: 500,
}));

export const StyledButton = styled(Button)(({ buttoncolor }) => ({
  fontSize: 10,
  padding: 10,
  backgroundColor: buttoncolor,
  color: "#fff",
  "&:hover": {
    backgroundColor: darken(buttoncolor, 0.2),
  },
}));

export const StyledSelect = styled(Select)(({ textcolor, basecolor, bordercolor }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  color: textcolor,
  fontSize: 12,
  fontWeight: 700,
  minWidth: "50px",
  textAlign: "center",
  borderBottom: 0,
  backgroundColor: basecolor,
  // border:`3px solid ${bordercolor}`,
  // boxShadow: `inset 0 0 10px ${bordercolor}`,
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `2px solid ${bordercolor}`,
    boxShadow: `inset 0 0 7px ${bordercolor}`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove the default border
  },
  "& .MuiSelect-icon": {
    color: textcolor, // Ensure the dropdown icon is also white
  },
  "&:hover": {
    boxShadow: `inset 0 0 15px ${bordercolor}`,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme, bgcolor, hovercolor, textcolor }) => ({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  backgroundColor: `${bgcolor} !important`,
  color: `${textcolor} !important`,
  "&:hover": {
    backgroundColor: `${hovercolor} !important`,
    color: `#FFFFFF !important`,
  },
}));

export const StyledMenu = styled(Menu)(({ bordercolor }) => ({
  "& .MuiList-root": {
    border: `2px solid ${bordercolor} !important`,
    boxShadow: `inset 0 0 7px ${bordercolor} !important`,
  },
}));

export const StyledAlert = styled(Alert)(() => ({
  width: "100%",
  backgroundColor: colors.scoreCardBlue, // Match the background of your app
  color: colors.pureWhite, // Bright green for the text
  borderRadius: "8px", // Rounded corners
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
  display: "flex",
  alignItems: "center",
}));

// Custom Icon with the desired color
export const StyledSaveIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: colors.brightGreen, // Set the color of the checkmark here
  marginRight: theme.spacing(1), // Optional: Adjust spacing to the right of the icon
}));

// Styled Dialog
const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: colors.scoreCardBlue, // Background color for the dialog
    color: colors.pureWhite, // Text color
    borderRadius: "8px", // Rounded corners
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    padding: "16px", // Padding inside the dialog
  },
}));

// Styled Dialog Title
const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: colors.pureWhite,
  paddingBottom: "8px", // Slight padding below the title
}));

// Styled Dialog Content
const StyledDialogContent = styled(DialogContent)(() => ({
  color: colors.pureWhite,
  paddingBottom: "16px",
}));

// Styled Dialog Actions
const StyledDialogActions = styled(DialogActions)(() => ({
  justifyContent: "flex-end",
  paddingTop: "8px",
}));

export const CustomDialog = ({ open, onClose, title, children, onConfirm }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContent>
        <Typography sx={{ color: colors.white }} variant="body1">
          {children}
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton buttoncolor={colors.red} onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton buttoncolor={colors.brightGreen} onClick={onConfirm}>
          Confirm
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export const SquareButton = styled(Button)(({ buttoncolor }) => ({
  width: "100%",
  aspectRatio: "1 / 1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: 10,
  padding: 10,
  backgroundColor: buttoncolor,
  color: "#fff",
  "&:hover": {
    backgroundColor: darken(buttoncolor, 0.2),
  },
}));

export const SquareBox = styled(Box)(({ theme, boxcolor }) => ({
  aspectRatio: "1 / 1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: 10,
  padding: 10,
  backgroundColor: boxcolor,
  color: "#fff",
  cursor: "pointer",
  transition: theme.transitions.create(["background-color", "box-shadow"], {
    duration: theme.transitions.duration.short,
  }),
  boxShadow: theme.shadows[2], // Default Material-UI button shadow
  borderRadius: theme.shape.borderRadius, // Default Material-UI button border radius
  textTransform: "uppercase", // Default for Material-UI buttons
  "&:hover": {
    backgroundColor: darken(boxcolor, 0.2),
    boxShadow: theme.shadows[4], // Slightly larger shadow on hover
  },
  "&:active": {
    boxShadow: theme.shadows[8], // Larger shadow when pressed
  },
}));

export const StyledBox = styled(Box)(({ theme, team }) => ({
  color: "#FFFFFF",
  fontSize: 14,
  fontWeight: 700,
  position: "relative",
  textAlign: "center",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: team === "Red" ? `${colors.darkBlue}` : `${colors.darkBlue}`,
  border: team === "Red" ? `3px solid ${colors.red}` : `3px solid ${colors.lightBlue}`,
  boxShadow: team === "Red" ? `inset 0 0 10px  ${colors.red}` : `inset 0 0 10px  ${colors.lightBlue}`,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: team === "Red" ? `inset 0 0 20px  ${colors.red}` : `inset 0 0 20px  ${colors.lightBlue}`,
  },
  padding: theme.spacing(1),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: colors.white,
  fontSize: 12,
  fontWeight: 300,
  letterSpacing: "0.1em",
  textAlign: "center",
  marginLeft: 0,
  borderBottom: 0,
  padding: 0,
  "& .MuiFormControlLabel-label": {
    fontSize: 12,
    fontWeight: 300,
    letterSpacing: "0.1em",
  },
}));

export const StyledSwitch = styled(Switch)(({ theme, switchcolor }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: switchcolor, // Green color when checked
    "&:hover": {
      backgroundColor: alpha(switchcolor, theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: switchcolor, // Green color for the track when checked
  },
}));
