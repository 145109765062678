import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TournamentPage from './pages/TournamentPage';
import PlayerPage from './pages/PlayerPage';
import { AuthProvider } from './context/AuthContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './reset.css';
import './global.css';

let theme = createTheme({
  typography: {
    fontFamily: "Lato"
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<TournamentPage />} />
            <Route path="/players" element={<PlayerPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
