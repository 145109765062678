import React, { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Modal, Box, Typography, Grid, Button } from "@mui/material";
import { SquareButton, SquareBox, StyledButton } from "../styles/StyledComponents";
import "./ScoreCardTable.css";
import { colors } from "../styles/Colors";

const ScoreModal = ({ open, onClose, scoreInfo, playerTeamName, holeIndex, onScoreSelect }) => {
  const { auth } = useContext(AuthContext);
  const [customScore, setCustomScore] = useState("");

  const scoreTitles = {
    "-4": "Leehoo",
    "-3": "Albatross",
    "-2": "Eagle",
    "-1": "Birdie",
    0: "Par",
    1: "Bogey",
    2: "Double",
    3: "Triple",
    4: "Quadruple",
  };
  const scoreButtons = Array.from({ length: 8 }, (_, i) => i + 1);

  const handleScoreSelect = (score) => {
    onScoreSelect(scoreInfo?.player?._id, holeIndex, score);
    onClose();
  };

  const handleCustomScoreSubmit = () => {
    if (customScore) {
      onScoreSelect(scoreInfo?.player?._id, holeIndex, Number(customScore));
      onClose();
    }
  };

  const getScoreClass = (score, par) => {
    const difference = score - par;

    if (difference <= -2) {
      return "eagle";
    } else if (difference === -1) {
      return "birdie";
    } else if (difference === 0) {
      return "par";
    } else if (difference === 1) {
      return "bogey";
    } else if (difference >= 2) {
      return "double-bogey";
    }

    return "";
  };

  const getScoreTitle = (score, par) => {
    const difference = score - par;
    if (score === 1) {
      return "Ace";
    }

    if (difference <= 4) {
      return scoreTitles[difference];
    } else {
      return `${difference} Over`;
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="score-modal-title">
      <Box
        elevation={20}
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          bgcolor: colors.darkBlue,
          borderRadius: 5,
          // border: 2,
          // borderColor: colors.brightGreen,
          // boxShadow: `inset 0 0 20px  ${colors.brightGreen}`,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 8px 8px rgba(0, 8, 24, 0.15)",
        }}>
        <Typography
          id="score-modal-title"
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: 500, letterSpacing: "0.1em", color: colors.white }}>
          HOLE {scoreInfo?.hole?.holeNumber}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ fontWeight: 300, letterSpacing: "0.1em", color: colors.white }}>
          | Par {scoreInfo?.hole?.par} |
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 300, letterSpacing: "0.1em", color: colors.white }}>
          {playerTeamName}
        </Typography>
        <Grid container spacing={2}>
          {scoreButtons.map((score) => (
            <Grid item xs={4} key={score}>
              <SquareButton
                className="scoreButton"
                buttoncolor={colors.scoreCardBlue}
                variant="contained"
                fullWidth
                onClick={() => handleScoreSelect(score)}>
                <Box className="scoreOption">
                  <div className="scoreTitle">{getScoreTitle(score, scoreInfo?.hole?.par)}</div>
                  <div className={`scoreValue ${getScoreClass(score, scoreInfo?.hole?.par)}`}>{score}</div>
                </Box>
              </SquareButton>
            </Grid>
          ))}
          <Grid item xs={4}>
            <div className="scoreButton">
              <SquareBox variant="contained" boxcolor={colors.scoreCardBlue} className="scoreOption">
                <div className="scoreTitle">CUSTOM SCORE</div>
                <div className="scoreValue customScore">
                  <input
                    onChange={(e) => {
                      const value = e.target.value;
                      // Only update if the new value is empty or a number with 1 or 2 digits
                      if (value === "" || (/^\d{1,2}$/.test(value) && parseInt(value, 10) <= 99)) {
                        setCustomScore(value);
                      }
                    }}
                    type="text"
                    value={customScore}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "inherit",
                    }}
                  />
                </div>
              </SquareBox>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="modalButtonGroup">
              {auth && (
                <StyledButton className="clearButton" buttoncolor={colors.red} onClick={onClose}>
                  Cancel
                </StyledButton>
              )}
              {auth && customScore !== "" && (
                <StyledButton className="saveButton" buttoncolor={colors.brightGreen} onClick={handleCustomScoreSubmit}>
                  Save
                </StyledButton>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ScoreModal;
