import React, { useMemo } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import PlayerProfileCard from './PlayerProfileCard';

const PlayerGrid = ({ players, teamColor }) => {
  const isNarrow = useMediaQuery('(max-width:1449px)');

  const sortedPlayers = useMemo(() => {
    // Filter players by team color
    const filteredPlayers = players.filter(player => player.team?.color === teamColor);

    // Sort players by handicap in ascending order
    const sortedByHandicap = filteredPlayers.sort((a, b) => a.handicap - b.handicap);

    // Find the captain
    const captain = sortedByHandicap.find(player => player.isCaptain);

    // Remove the captain from the array if found
    const playersWithoutCaptain = captain 
      ? sortedByHandicap.filter(player => player._id !== captain._id)
      : sortedByHandicap;

    // Return the array with the captain at the beginning if found
    return captain ? [captain, ...playersWithoutCaptain] : playersWithoutCaptain;
  }, [players, teamColor]);

  return (
    <Grid container spacing={4}>
      {sortedPlayers.map((player) => (
        <Grid 
          item 
          key={player._id}
          xs={12}
          {...(isNarrow ? {} : { sm: 6 })}
        >
          <PlayerProfileCard player={player} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlayerGrid;