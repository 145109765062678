import React, { useContext, useState, useEffect } from "react";
import { Typography, ToggleButtonGroup, Grid, Snackbar, Box } from "@mui/material";
import {
  fetchHoles,
  fetchPlayers,
  fetchTournaments,
  fetchEvents,
  fetchRounds,
  fetchMatches,
  fetchMatchPoints,
  fetchEventPoints,
  fetchScoresByEvent,
  fetchScoresByTournament,
  fetchTournamentPlayers,
  fetchTournamentPoints,
  postScores,
  postRounds,
} from "../services/api";
import AuthContext from "../context/AuthContext";
import "./TournamentPage.css"; // Import the CSS file
import {
  StyledToggleButton,
  StyledAlert,
  StyledSaveIcon,
  StyledSelect,
  StyledMenuItem,
  StyledButton,
  StyledText,
} from "../styles/StyledComponents";
import { colors } from "../styles/Colors";
// import logo from '../images/GolfAI.svg';
import ClipLoader from "react-spinners/ClipLoader";
import ScoreCardTable from "../components/ScoreCardTable";
import LeaderboardTable from "../components/LeaderboardTable";
import TournamentStatus from "../components/TournamentStatus";
import MatchStatus from "../components/MatchStatus";
import CourseBanner from "../components/CourseBanner";
import LoginModal from "../components/LoginModal";
import PageHeader from "../components/PageHeader";

const eventViews = {
  LEADERBOARD: "leaderBoardView",
  MATCHES: "matchesView",
};

const TournamentPage = () => {
  const { auth, login } = useContext(AuthContext);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedMatch, setSelectedMatch] = useState("");
  const [selectedMatchPoints, setSelectedMatchPoints] = useState({});
  const [selectedEventPoints, setSelectedEventPoints] = useState({});
  const [tournamentPoints, setTournamentPoints] = useState({});
  const [eventView, setEventView] = useState(eventViews.LEADERBOARD);
  const [course, setCourse] = useState(null);
  const [leaderBoard, setLeaderBoard] = useState({});
  const [scoreCard, setScoreCard] = useState({}); // State to store the course with holes
  const [holes, setHoles] = useState([]);
  const [players, setPlayers] = useState([]);
  const [tournamentPlayers, setTournamentPlayers] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [matches, setMatches] = useState([]);
  const [events, setEvents] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loginError, setLoginError] = useState("");
  const [inputError, setInputError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [arePointsLoading, setArePointsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [loginOpen, setLoginOpen] = useState(false);
  const [scoresToSave, setScoresToSave] = useState([]);

  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);

  const loadHoles = async (courseId) => {
    try {
      const holeData = await fetchHoles(courseId);
      setHoles(holeData);
    } catch (error) {
      console.error("Error retrieving course", error);
    }
  };

  const loadPlayers = async (tournamentId) => {
    try {
      const playerData = await fetchPlayers(tournamentId);
      setPlayers(playerData);
    } catch (error) {
      console.error("Error retrieving players", error);
    }
  };

  const loadTournaments = async () => {
    try {
      const tournamentData = await fetchTournaments();
      setTournaments(tournamentData);
    } catch (error) {
      console.error("Error retrieving tournaments", error);
    }
  };

  const loadTournamentPlayers = async (tournamentId) => {
    try {
      const tournamentPlayersData = await fetchTournamentPlayers(tournamentId);
      setTournamentPlayers(tournamentPlayersData);
    } catch (error) {
      console.log("TOURNAMENT PLAYERS ERROR: ", error);
      console.error("Error retrieving tournament players", error);
    }
  };

  const loadTournamentPoints = async (tournamentId) => {
    try {
      const tournamentPointsData = await fetchTournamentPoints(tournamentId);
      setTournamentPoints(tournamentPointsData);
      setArePointsLoading(false);
    } catch (error) {
      console.error("Error retrieving tournament points", error);
    }
  };

  const loadEvents = async (tournamentId) => {
    try {
      let eventsData = await fetchEvents(tournamentId);
      eventsData = [{ name: "Overall" }, ...eventsData];
      setEvents(eventsData);
    } catch (error) {
      console.error("Error retrieving events", error);
    }
  };

  const loadMatches = async (eventId) => {
    try {
      const matchesData = await fetchMatches(eventId);
      setMatches(matchesData);
    } catch (error) {
      console.error("Error retrieving matches", error);
    }
  };

  const loadMatchPoints = async (matchId) => {
    try {
      const matchPointsData = await fetchMatchPoints(matchId);
      setSelectedMatchPoints(matchPointsData);
      setArePointsLoading(false);
    } catch (error) {
      console.error("Error retrieving match points", error);
    }
  };

  const loadEventPoints = async (eventId, match) => {
    try {
      if (match && match?.matchType?.gameFormat === "LIV") {
        const matchPointsData = await fetchEventPoints(eventId);
        setSelectedEventPoints(matchPointsData);
        setArePointsLoading(false);
      }
    } catch (error) {
      console.error("Error retrieving event points", error);
    }
  };

  const loadRounds = async (eventId) => {
    try {
      const roundsData = await fetchRounds(eventId);
      setRounds(roundsData);
    } catch (error) {
      console.error("Error retrieving rounds", error);
    }
  };

  const loadScoresByEvent = async (eventId) => {
    try {
      console.log("fetching scores for event");
      const scoreByEventsData = await fetchScoresByEvent(eventId);
      return scoreByEventsData;
    } catch (error) {
      console.error("Error retrieving scores", error);
      return [];
    }
  };

  const loadScoresByTournament = async (tournamentId) => {
    try {
      console.log("fetching scores for tournament");
      const scoreByTournamentData = await fetchScoresByTournament(tournamentId);
      setIsLoading(false);
      return scoreByTournamentData;
    } catch (error) {
      console.error("Error retrieving scores", error);
      return [];
    }
  };

  function groupScoresByEventId(scores, eventIds) {
    const groupedScores = {};

    scores.forEach((score) => {
      try {
        const eventId = score.match.event;

        if (eventIds.includes(eventId)) {
          // If the eventId is already a key, push the score into the existing array
          if (!groupedScores[eventId]) {
            groupedScores[eventId] = [];
          }

          groupedScores[eventId].push(score);
        }
      } catch (error) {}
    });
    console.log("GROUPED SCORES: ", groupedScores);
    return groupedScores;
  }

  const loadLeaderboard = async () => {
    const scores = await loadScoresByTournament(tournaments[0]._id);
    console.log("LEADERBOARD SCORES; ", scores);
    setLeaderBoard(createLeaderboard(scores));
  };

  const createLeaderboard = (scores) => {
    const leaderScoreCards = {};

    // organize scores by event
    console.log("LEADER SCORES: ", scores);
    const filteredEvents = events.filter((event) => event.name !== "Overall");
    const eventIds = filteredEvents.map((event) => event._id);
    console.log("EVENT IDS: ", eventIds);
    const scoresByEvent = groupScoresByEventId(scores, eventIds);
    console.log("SCORES BY EVENT: ", scoresByEvent);
    // Transform each array of scores into a scorecard object using createScoreCard
    filteredEvents.forEach((event) => {
      console.log("MAKING SCORECARD FOR: ", event._id);
      const eventScoreCard = createScoreCard(scoresByEvent[event._id]);
      console.log("EVENT SCORECARD: ", eventScoreCard);
      leaderScoreCards[event.name] = eventScoreCard;
    });
    console.log("LEADERBOARD - OVERALL: ", leaderScoreCards);
    return leaderScoreCards;
  };

  const loadScores = async () => {
    if (rounds && rounds.length > 0 && selectedEvent && selectedMatch) {
      const currentRounds = rounds.filter((round) => round.event === selectedEvent._id);
      if (currentRounds.length > 0) {
        const scores = await loadScoresByEvent(selectedEvent._id);
        console.log("SCORES; ", scores);
        setScoreCard(createScoreCard(scores));
        setIsLoading(false);
      }
    }
  };

  const createScoreCard = (scores) => {
    // Create a map to store scorecards, keyed by match ID
    console.log("SCORE ARRAY: ", scores);
    const scoreCardsByMatch = {};
    scores.forEach((score) => {
      // If the player has been assigned to a match, popluate their scores on the correct score card
      if (score.match?._id) {
        const playerId = score.player._id;
        const holeNumber = score.hole.holeNumber;
        const matchId = score.match._id; // Get the match ID from the score

        // If the match doesn't exist in the map, initialize its entry
        if (!scoreCardsByMatch[matchId]) {
          scoreCardsByMatch[matchId] = {};
        }

        // If the player doesn't exist in the match's scorecard, initialize their entry
        if (!scoreCardsByMatch[matchId][playerId]) {
          const playerInfo = score.player;
          playerInfo["team"] = tournamentPlayers[playerId]?.team;
          playerInfo["isCaptain"] = tournamentPlayers[playerId]?.isCaptain;
          scoreCardsByMatch[matchId][playerId] = {
            playerInfo: playerInfo,
            round: score.round,
            match: score.match,
            scores: Array(18).fill(null), // Assuming 18 holes, initialize with nulls
          };
        }

        // Place the score in the correct hole position (holeNumber - 1 since array is 0-indexed)
        scoreCardsByMatch[matchId][playerId].scores[holeNumber - 1] = score;
      }
    });

    // Return the organized scorecards grouped by match ID
    console.log("ScoreCard: ", scoreCardsByMatch);
    console.log("Selected Match: ", selectedMatch);
    console.log("matches: ", matches);
    return scoreCardsByMatch;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    loadTournaments();
  }, []);

  useEffect(() => {
    if (tournaments.length > 0) {
      setIsLoading(true);
      loadEvents(tournaments[0]._id);
      loadPlayers(tournaments[0]._id);
      loadTournamentPlayers(tournaments[0]._id);
    }
  }, [tournaments]);

  useEffect(() => {
    if (events.length > 0) {
      const firstEvent = events[0];
      setSelectedEvent(firstEvent);
      // const course = firstEvent.course;
      // setCourse(course);
      // loadHoles
      // (course._id);
      // loadRounds(firstEvent._id);
      // loadMatches(firstEvent._id);
    }
  }, [events]);

  useEffect(() => {
    if (matches.length > 0) {
      console.log("SETTING MATCH FROM: ", matches);
      const firstMatch = matches[0];
      console.log(firstMatch);
      setSelectedMatch(firstMatch);
    }
  }, [matches]);

  useEffect(() => {
    if (selectedEvent?.name === "Overall") {
      console.log("LOADING LEADERBOARD");
      setArePointsLoading(true);
      loadTournamentPoints(tournaments[0]._id);
      loadLeaderboard();
    } else if (events.length > 0) {
      setIsLoading(true);
      loadRounds(selectedEvent._id);
      loadMatches(selectedEvent._id);
      loadScores();
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (matches && matches.length > 0) {
      setIsLoading(true);
      setArePointsLoading(true);
      const course = selectedMatch.course;
      setCourse(course);
      loadHoles(course._id);
      loadRounds(selectedEvent._id);
      loadMatchPoints(selectedMatch._id);
      loadEventPoints(selectedEvent._id, selectedMatch);
      loadScores();
    }
  }, [selectedMatch, eventView]);

  useEffect(() => {
    console.log("LOADING NEW ROUNDS: ", rounds);
    if (rounds && rounds.length > 0) {
      console.log("LOADING NEW ROUNDS: ", rounds);
      loadScores();
    }
  }, [rounds]);

  const handleEventChangeSelect = async (event) => {
    const newEvent = event.target.value;
    if (newEvent !== null) {
      setSelectedEvent(newEvent);
    }
  };

  const handleEventChange = async (event, newEvent) => {
    if (newEvent !== null) {
      setSelectedEvent(newEvent);
    }
  };

  const handleMatchChange = async (event, newMatch) => {
    console.log("NEW MATCH: ", newMatch);
    if (newMatch !== null) {
      setSelectedMatch(newMatch);
    }
  };

  const handleEventViewChange = async (event, newEventView) => {
    console.log("NEW EVENT VIEW: ", newEventView);
    if (newEventView !== null) {
      setEventView(newEventView);
    }
  };

  const updateScore = (matchId, playerId, holeIndex, newScore) => {
    const updatedData = { ...scoreCard };
    if (newScore === "" || /^\d{0,2}$/.test(newScore)) {
      updatedData[matchId][playerId].scores[holeIndex].strokeNumber = parseInt(newScore, 10) || null;
      setScoresToSave((prevScoresToSave) => [...prevScoresToSave, updatedData[matchId][playerId].scores[holeIndex]]);
      setScoreCard(updatedData);
    }
  };

  const serializeScores = (newScores) => {
    const collapsedScores = newScores.map((score) => ({
      _id: score._id,
      strokeNumber: score.strokeNumber,
      hole: score.hole._id,
      player: score.player._id,
      round: score.round._id,
      match: score.match._id,
      type: score.type,
    }));

    console.log(collapsedScores);

    return collapsedScores;
  };

  const persistClearedScores = async (clearedScores, matchId) => {
    try {
      console.log("SCORE TO SAVE: ", clearedScores);
      const scoresPayload = serializeScores(clearedScores);
      const postedScores = await postScores(scoresPayload, selectedEvent._id, auth);
      let newScoreCard = { ...scoreCard };
      const newScores = createScoreCard(postedScores);
      setSnackbarMessage("Saved successfully!");
      setSnackbarOpen(true);
      console.log("NEW SCORES: ", newScores);
      newScoreCard[matchId] = { ...newScores[matchId] };
      loadMatchPoints(selectedMatch._id);
      loadEventPoints(selectedEvent._id, selectedMatch);
      setScoreCard(newScoreCard); // reload page with scores
      setScoresToSave((prevScoresToSave) => {
        console.log("Clearing scoresToSave:", prevScoresToSave); // Log before clearing
        return [];
      });
    } catch (error) {
      console.error("Error saving scores:", error.response?.data || error);
    }
  };

  const saveScores = async (matchId) => {
    try {
      console.log("SCORE TO SAVE: ", scoresToSave);
      const scoresPayload = serializeScores(scoresToSave);
      const postedScores = await postScores(scoresPayload, selectedEvent._id, auth);
      let newScoreCard = { ...scoreCard };
      const newScores = createScoreCard(postedScores);
      setSnackbarMessage("Saved successfully!");
      setSnackbarOpen(true);
      console.log("NEW SCORES: ", newScores);
      newScoreCard[matchId] = { ...newScores[matchId] };
      loadMatchPoints(selectedMatch._id);
      loadEventPoints(selectedEvent._id, selectedMatch);
      setScoreCard(newScoreCard); // reload page with scores
      // Reset scoresToSave in a useEffect to ensure all processing is complete
      setScoresToSave((prevScoresToSave) => {
        console.log("Clearing scoresToSave:", prevScoresToSave); // Log before clearing
        return [];
      });
    } catch (error) {
      console.error("Error saving scores:", error.response?.data || error);
    }
  };

  // const handleRegister= async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await register(username, password);
  //     console.log("LOGIN: ", response.data);
  //     setSnackbarMessage("User registered successfully!");
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     setLoginError('Register failed. Please check your credentials.');
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError("");
    try {
      const response = await login(username, password);
      console.log("LOGIN: ", response.data);
      setSnackbarMessage("Logged in successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setLoginError("Login failed. Please check your credentials.");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const clearScores = (matchId, clearType, clearTarget) => {
    const newScoreCard = { ...scoreCard }; // Create a shallow copy of the object
    let updatedScoresToSave = []; // Initialize a new array to accumulate scores to save

    console.log("CLEARING SCORECARD ", newScoreCard);

    if (clearType === "column") {
      // Clear the score for a specific hole (column) across all players
      Object.keys(newScoreCard[matchId]).forEach((playerId) => {
        newScoreCard[matchId][playerId] = {
          ...newScoreCard[matchId][playerId],
          scores: newScoreCard[matchId][playerId].scores.map((score, index) =>
            index === clearTarget ? { ...score, strokeNumber: null } : score
          ),
        };

        // Update the scores to be saved in a batch
        updatedScoresToSave = updatedScoresToSave.concat(newScoreCard[matchId][playerId].scores);
      });
    } else if (clearType === "all") {
      // Clear all scores for all players
      Object.keys(newScoreCard[matchId]).forEach((playerId) => {
        newScoreCard[matchId][playerId] = {
          ...newScoreCard[matchId][playerId],
          scores: newScoreCard[matchId][playerId].scores.map((score) => ({
            ...score,
            strokeNumber: null,
          })),
        };

        // Update the scores to be saved in a batch
        updatedScoresToSave = updatedScoresToSave.concat(newScoreCard[matchId][playerId].scores);
      });
    }

    // Log the accumulated scores before updating state
    console.log("ACCUMULATED SCORES TO SAVE: ", updatedScoresToSave);

    // Update the state outside the loop to ensure all updates are done in a batch

    // Log after attempting to set the state (note that this might still not reflect the immediate state change)
    console.log("SCORES TO SAVE AFTER SET: ", scoresToSave);

    // Call saveScores after updating state
    persistClearedScores(updatedScoresToSave, matchId);
  };

  const addRoundToMatch = (round, matchId) => {
    // if a round already exists for this player in the event, update that record with the new matchId, if there is no round yet, create the round record
    const upsertedRound = [
      {
        round: round._id,
        date: selectedEvent.date,
        match: matchId,
        event: selectedEvent._id,
        player: round.player._id,
      },
    ];
    console.log("ROUND TO ADD: ", upsertedRound);
    upsertRounds(upsertedRound);
  };

  const removeRoundsFromMatch = (roundsToRemove) => {
    const removeRounds = roundsToRemove.map((round) => ({
      round: round._id,
      date: selectedEvent.date,
      match: null,
      event: selectedEvent._id,
      player: round.player._id,
    }));
    console.log("ROUND TO REMOVE: ", removeRounds);
    upsertRounds(removeRounds);
  };

  // Function to upsert a round
  const upsertRounds = async (upsertedRounds) => {
    try {
      const postedRounds = await postRounds(upsertedRounds, selectedEvent._id, auth);
      console.log("Rounds upserted successfully:", postedRounds);
      setRounds(postedRounds);
      loadMatchPoints(selectedMatch._id);
      loadEventPoints(selectedEvent._id, selectedMatch);
    } catch (error) {
      console.error("Error upserting round:", error.response?.data || error.message);
      throw error;
    }
  };

  const isPlayerInScorecard = (scorecard, playerId) => {
    return Object.keys(scorecard).some((matchId) => {
      return Object.keys(scorecard[matchId]).includes(playerId);
    });
  };

  const calculateCellCount = (red, blue) => {
    return (red + blue) / 2;
  };

  return (
    <div className="tournamentPage">
      <Grid className="gridContainer" container rowSpacing={0}>
        <Grid item xs={12}>
          <PageHeader />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {isMobile && events.length > 5 ? (
            <StyledSelect
              className="eventDropDown"
              value={selectedEvent}
              onChange={handleEventChangeSelect}
              displayEmpty
              fullWidth
              MenuProps={{
                MenuListProps: {
                  disablePadding: true,
                  sx: {
                    borderRadius: "2px",
                    border: `2px solid ${colors.lightGreen} !important`,
                    boxShadow: `inset 0 0 10px ${colors.lightGreen} !important`,
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: `inset 0 0 10px ${colors.lightGreen}`,
                    },
                  },
                },
              }}
              elevation={20}
              sx={{ boxShadow: "0px 8px 8px rgba(0, 8, 24, 0.15)" }}
              textcolor={colors.white}
              basecolor={colors.darkBlue}
              bordercolor={colors.lightGreen}>
              {events.map((event) => (
                <StyledMenuItem
                  bgcolor={colors.darkBlue}
                  textcolor={colors.white}
                  hovercolor={colors.scoreCardHover}
                  key={event._id}
                  value={event}>
                  {event.name}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          ) : (
            <ToggleButtonGroup
              value={selectedEvent}
              exclusive
              onChange={handleEventChange}
              aria-label="event selection"
              fullWidth
              elevation={20}
              sx={{ boxShadow: "0px 8px 8px rgba(0, 8, 24, 0.15)" }}>
              {events.length > 0 &&
                events.map((event) => (
                  <StyledToggleButton
                    selectedcolor={colors.lightGreen}
                    hovercolor={colors.hoverlightGreen}
                    textcolor={colors.white}
                    key={event._id}
                    value={event}>
                    {event.name}
                  </StyledToggleButton>
                ))}
            </ToggleButtonGroup>
          )}
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 0 }}>
          <ToggleButtonGroup
            value={eventView}
            exclusive
            onChange={handleEventViewChange}
            aria-label="event view selection"
            fullWidth
            elevation={20}
            sx={{ boxShadow: "0px 8px 8px rgba(0, 8, 24, 0.15)" }}>
            {matches.length > 0 && selectedEvent?.name !== "Overall" && (
              <>
                <StyledToggleButton
                  selectedcolor={colors.orange}
                  hovercolor={colors.hoverOrange}
                  textcolor={colors.white}
                  key="leaderBoardView"
                  value={eventViews.LEADERBOARD}>
                  LEADERBOARD
                </StyledToggleButton>
                <StyledToggleButton
                  selectedcolor={colors.orange}
                  hovercolor={colors.hoverOrange}
                  textcolor={colors.white}
                  key="matchesView"
                  value={eventViews.MATCHES}>
                  MATCHES
                </StyledToggleButton>
              </>
            )}
          </ToggleButtonGroup>
        </Grid>

        {selectedEvent?.name !== "Overall" && eventView === eventViews.MATCHES && (
          <Grid item xs={12} sx={{ marginTop: 0 }}>
            <ToggleButtonGroup
              value={selectedMatch}
              exclusive
              onChange={handleMatchChange}
              aria-label="match selection"
              fullWidth
              elevation={20}
              sx={{ boxShadow: "0px 8px 8px rgba(0, 8, 24, 0.15)" }}>
              {matches.length > 0 &&
                matches.map((match) => (
                  <StyledToggleButton
                    selectedcolor={colors.brightGreen}
                    hovercolor={colors.hoverbrightGreen}
                    textcolor={colors.white}
                    key={match.id}
                    value={match}>
                    {match.name}
                  </StyledToggleButton>
                ))}
            </ToggleButtonGroup>
          </Grid>
        )}
        {/* {!(isLoading || isLeaderBoardLoading) && (selectedEvent?.name === "Overall") && 
          <Grid item xs={12} sx={{mt:0, mb:3}}>
            <TournamentStatus redPoints={tournamentPoints.teamRed} bluePoints={tournamentPoints.teamBlue} title={"POINTS NEEDED"} pointsToWin={30.5} totalCells={20} pointsPerCell={3}  />     
          </Grid>
        } */}
        {isLoading || arePointsLoading ? (
          <Grid item xs={12} sx={{ marginTop: 0 }} className="spinner-container">
            <ClipLoader color={colors.loadingGreen} loading={isLoading || arePointsLoading} />
          </Grid>
        ) : eventView === eventViews.LEADERBOARD || (leaderBoard && selectedEvent?.name === "Overall") ? (
          <Grid item xs={12} sx={{ marginTop: 0 }}>
            {leaderBoard && selectedEvent?.name === "Overall" ? (
              <>
                <Grid item xs={12} sx={{ mt: 0, mb: 3 }}>
                  <TournamentStatus
                    redPoints={tournamentPoints?.teamRed}
                    bluePoints={tournamentPoints?.teamBlue}
                    title={"POINTS NEEDED"}
                    pointsToWin={25}
                    totalCells={20}
                    pointsPerCell={2.5}
                    totalsOnly={false}
                  />
                </Grid>
                <LeaderboardTable
                  scorecard={leaderBoard}
                  eventName={selectedEvent?.name}
                  overall={true}
                  divideTeams={false}
                />
              </>
            ) : (
              <>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  {selectedMatch && selectedMatch?.matchType?.gameFormat === "LIV" && (
                    <Grid item xs={12} sx={{ mt: 0, mb: 3 }}>
                      <TournamentStatus
                        redPoints={selectedEventPoints?.results?.teamRed?.total}
                        bluePoints={selectedEventPoints?.results?.teamBlue?.total}
                        title={"POINTS AVAILABLE"}
                        pointsToWin={5}
                        totalCells={0}
                        pointsPerCell={18}
                        totalsOnly={true}
                      />
                    </Grid>
                  )}
                  {
                    <LeaderboardTable
                      scorecard={{ [selectedEvent?.name]: scoreCard }}
                      eventName={selectedEvent?.name}
                      overall={false}
                      divideTeams={selectedMatch?.matchType?.gameFormat === "LIV" ? true : false}
                    />
                  }
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          scoreCard &&
          selectedEvent.name !== "Overall" &&
          eventView === eventViews.MATCHES && (
            <>
              <Grid item xs={12} sx={{ mt: 2, mb: 0 }}>
                <CourseBanner
                  courseName={selectedMatch.course.name}
                  logo={selectedMatch.course.logo}
                  backgroundImage={selectedMatch.course.photo}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 1, mb: 0, flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 700, paddingLeft: 1, letterSpacing: "0.1em", color: colors.white }}>
                    {selectedMatch?.name.toUpperCase()}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 12, fontWeight: 300, paddingLeft: 1, letterSpacing: "0.1em", color: colors.white }}>
                    {selectedMatch?.matchType?.teamFormat.toUpperCase() +
                      " " +
                      selectedMatch?.matchType?.gameFormat.toUpperCase()}
                  </Typography>
                  {selectedMatch && selectedMatch?.matchType?.gameFormat !== "LIV" && (
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 300,
                        paddingLeft: 1,
                        letterSpacing: "0.1em",
                        color: colors.white,
                      }}>
                      {" (3 POINTS)"}
                    </Typography>
                  )}
                </Box>
              </Grid>
              {/* <Grid item xs={12} sx={{marginTop:0}}>
                <MatchStatus scorecard={scoreCard[selectedMatch._id]} holes={holes} />
              </Grid> */}
              <Grid item xs={12} sx={{ mt: 0, mb: 0 }}>
                {selectedMatch && selectedMatch?.matchType?.gameFormat === "LIV" ? (
                  <></>
                ) : (
                  selectedMatchPoints && selectedMatchPoints.results && (
                    <MatchStatus
                      teamRed={selectedMatchPoints?.results?.teamRed}
                      teamBlue={selectedMatchPoints?.results?.teamBlue}
                    />
                  )
                )}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 0 }}>
                <ScoreCardTable
                  key={selectedMatch._id}
                  match={selectedMatch}
                  players={players}
                  availablePlayers={players.filter((player) => !isPlayerInScorecard(scoreCard, player._id))}
                  rounds={rounds}
                  holes={holes}
                  scoreCard={scoreCard[selectedMatch._id] ? scoreCard[selectedMatch._id] : {}}
                  addRoundToMatch={addRoundToMatch}
                  removeRoundsFromMatch={removeRoundsFromMatch}
                  updateScore={updateScore}
                  saveScores={saveScores}
                  clearScores={clearScores}
                  matchPoints={selectedMatchPoints}
                />
              </Grid>
            </>
          )
        )}
        <Grid item xs={12} sx={{ mt: 3 }}>
          <div className="pageFooterRow">
            <Typography
              gutterBottom
              sx={{ fontSize: 12, fontWeight: 700, mb: 0, mt: 0.75, letterSpacing: "0.2em", color: colors.white }}>
              POWERED BY
            </Typography>
            <Box
              onClick={handleLoginOpen}
              sx={{
                cursor: "pointer", // Makes the header clickable
              }}>
              <img className="golfAilogo" alt="logo" align="center" src={"/images/logos/GolfAI.svg"}></img>
            </Box>
            <LoginModal open={loginOpen} onClose={handleLoginClose} />
          </div>
        </Grid>
        {inputError && <Typography color="error">{inputError}</Typography>}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom", // Positioned at the top of the page
            horizontal: "center", // Centered horizontally
          }}
          sx={{
            // Add margin or set the distance from the bottom
            marginBottom: "75px", // Adjust this value to position it higher
          }}>
          <StyledAlert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
            icon={<StyledSaveIcon />}>
            {snackbarMessage}
          </StyledAlert>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default TournamentPage;
