export const abbreviateName = (fullName) => {
  const names = fullName.split(" ");
  if (names.length === 1) return fullName; // If it's a single name, return as is
  const firstName = names[0];
  return `${firstName}`;
};

export const getTeamMemberNames = (scorecard, playerName, match) => {
  // Convert scorecard object to array
  const players = Object.values(scorecard);

  // Find the player with the given name
  const player = players.find((p) => p?.playerInfo?.name === playerName);

  if (!player) {
    console.log("NO name found: ", playerName);
    return playerName; // Return original name if player not found
  }

  // Find teammate (player with same team color but different name)
  const teammate = players.find(
    (p) => p?.playerInfo?.team?.color === player?.playerInfo?.team?.color && p?.playerInfo?.name !== playerName
  );

  if (!teammate) {
    return playerName; // Return original name if no teammate found
  }

  // Get first names of both players
  const playerFirstName = player?.playerInfo?.name.split(" ")[0];
  const teammateFirstName = teammate?.playerInfo?.name.split(" ")[0];

  // Combine names in alphabetical order
  const names = [playerFirstName, teammateFirstName].sort();
  if (match?.matchType?.gameFormat === "Scramble") {
    return `${names[0]} & ${names[1]}`;
  } else {
    return playerName;
  }
};
