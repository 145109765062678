// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderBoardContainer {
    margin-top:20px;
}
.leaderBoardContainerOverall {
    margin-top:5px;
}`, "",{"version":3,"sources":["webpack://./src/components/LeaderboardTable.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,cAAc;AAClB","sourcesContent":[".leaderBoardContainer {\n    margin-top:20px;\n}\n.leaderBoardContainerOverall {\n    margin-top:5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
