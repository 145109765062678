import React from 'react';
import { Box, Typography, Avatar, Grid, Divider, useMediaQuery, useTheme, Paper } from '@mui/material';
import { colors } from "../styles/Colors";

const PlayerProfileCard = ({ player }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const firstRowItems = [
    { label: "AGE", value: player.age },
    { label: "HANDICAP", value: player.handicap },
    { label: "HEIGHT", value: `${Math.floor(player.height / 12)}'${player.height % 12}"` },
    { label: "WEIGHT", value: `${player.weight} lbs` },
  ];

  const gradientColor = player.team.color === "Red" 
    ? `linear-gradient(180deg, ${colors.red} 0%, ${colors.darkRed} 100%)`
    : `linear-gradient(180deg, ${colors.lightBlue} 0%, ${colors.darkLightBlue} 100%)`;

  return (
    <Paper elevation={3} sx={{ 
      background: gradientColor,
      color: 'white',
      borderRadius: 2,
      overflow: 'hidden',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1), 0 6px 20px rgba(0,0,0,0.05)',
      position: 'relative',
    }}>
      <Box sx={{ 
        padding: 3,
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        position: 'relative',
        zIndex: 1,
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: isSmallScreen ? 'center' : 'flex-start',
          alignItems: 'center',
          marginBottom: isSmallScreen ? 3 : 0
        }}>
          <Avatar
            src={player.photo}
            alt={player.name}
            sx={{ 
              width: 150, 
              height: 150, 
              marginRight: isSmallScreen ? 0 : 3, 
              bgcolor: 'grey.300', 
              fontSize: '2rem',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
          >
            {player.name.charAt(0)}
          </Avatar>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ textAlign: isSmallScreen ? 'center' : 'left', display: 'flex', alignItems: 'center', justifyContent: isSmallScreen ? 'center' : 'flex-start' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, marginRight: 1 }}>
                {player.name}
              </Typography>
              {player.isCaptain && (
                <Box sx={{ 
                  backgroundColor: colors.brightGreen, 
                  color: 'white', 
                  width: 24, 
                  height: 24, 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  borderRadius: '4px',
                  marginBottom: 1,
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>C</Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: isSmallScreen ? 'center' : 'flex-start' }}>
              <img 
                src="/images/logos/canada-flag.svg" 
                alt="Flag" 
                style={{ marginRight: 8, width: 20, height: 15 }}
              />
              <Typography variant="subtitle2">CANADA - {player.province}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 3, justifyContent: isSmallScreen ? 'center' : 'flex-start', flexWrap: 'wrap' }}>
              {firstRowItems.map((item, index) => (
                <InfoItem key={index} label={item.label} value={item.value} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.3)', my: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
              <InfoItem label="HOME COURSE" value={player?.homeCourse} wide />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const InfoItem = ({ label, value, wide }) => (
  <Box sx={{ textAlign: 'left', flexBasis: 'auto' }}>
    <Typography variant="caption" sx={{ opacity: 0.7, fontWeight: 'bold', display: 'block' }}>
      {label}
    </Typography>
    <Typography variant="body2">
      {value}
    </Typography>
  </Box>
);

export default PlayerProfileCard;