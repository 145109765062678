import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import './CourseBanner.css';
import { colors } from "../styles/Colors";

const CourseBanner = ({ courseName, logo, backgroundImage }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 4,
        overflow: 'hidden', // Ensures child elements like background images don't overflow
        width: '100%', // Makes the Paper component take full width
      }}
    >
      <Box
        className="course-banner"
        sx={{
          position: 'relative', // Position relative to allow overlay
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: 200, md: 300 },
          width: '100%',
        }}
      >
        {/* Gradient overlay for side-to-center light transition */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6))', // Gradient from dark edges to lighter center
            zIndex: 1, // Ensure overlay is above the background but below the content
          }}
        />

        <Box
          sx={{
            position: 'relative', // Position relative for z-index stacking
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            color: colors.pureWhite,
            padding: 2,
            height: '100%',
            zIndex: 2, // Ensure content is above the overlay
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <Box
              component="img"
              src={logo}
              alt={`${courseName} Logo`}
              sx={{
                height: { xs: 40, md: 50 }, // Smaller height for the logos
                width: 'auto', // Preserve aspect ratio by using 'auto' for width
                marginRight: 2,
              }}
            />
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontSize: { xs: '2rem', md: '3rem' }, // Keep text size the same
                fontWeight: 'bold',
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)', // Add text shadow for additional contrast
              }}
            >
              {courseName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default CourseBanner;
