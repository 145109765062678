import React from "react";
import { Box, Typography, Paper, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../styles/Colors";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  position: "relative",
  height: "65px",
  display: "flex",
  backgroundColor: "transparent",
}));

const TeamScore = styled(Box)(({ theme, isRed }) => ({
  backgroundColor: isRed ? colors.red : colors.lightBlue,
  color: "white",
  width: "65px",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 700,
  fontSize: "1.4rem",
  zIndex: 2,
  borderRight: isRed ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
  borderLeft: !isRed ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
  [theme.breakpoints.up("sm")]: {
    width: "130px",
    fontSize: "2rem",
  },
}));

const FullTeamScore = styled(Box)(({ theme, isRed }) => ({
  backgroundColor: isRed ? colors.red : colors.lightBlue,
  color: "white",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 700,
  fontSize: "1.4rem",
  zIndex: 2,
  borderRight: isRed ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
  borderLeft: !isRed ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
    fontSize: "2rem",
  },
}));

const ScoreBar = styled(Box)({
  flex: 1,
  display: "flex",
  height: "100%",
  position: "relative",
});

const Cell = styled(Box)(({ isRed, isFilled, isMiddle }) => ({
  flex: 1,
  backgroundColor: isFilled ? (isRed ? colors.red : colors.lightBlue) : "rgba(255, 255, 255, 0.1)",
  borderRight: isMiddle ? "4px solid white" : "1px solid rgba(255, 255, 255, 0.2)",
  "&:last-child": {
    borderRight: "none",
  },
}));

const WinningLine = styled(Box)({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "50%",
  width: "4px",
  backgroundColor: "white",
  transform: "translateX(-50%)",
  zIndex: 1,
});

const TournamentStatus = ({ redPoints, bluePoints, title, totalCells, pointsPerCell, pointsToWin, totalsOnly }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const middleCellIndex = Math.floor(totalCells / 2) - 1;

  const redFilledCells = Math.min(Math.floor(redPoints / pointsPerCell), totalCells);
  const blueFilledCells = Math.min(Math.floor(bluePoints / pointsPerCell), totalCells);

  const redWon = redPoints >= pointsToWin;
  const blueWon = bluePoints >= pointsToWin;

  return (
    <Box sx={{ maxWidth: "100%", margin: "auto", pt: 0, pr: 1, pl: 1, pb: 0 }}>
      <Typography
        gutterBottom
        sx={{
          fontWeight: 700,
          fontSize: "1rem",
          display: "flex",
          justifyContent: "center",
          letterSpacing: "0.1em",
          color: colors.white,
          marginBottom: 0,
        }}>
        {pointsToWin}
      </Typography>
      <Typography
        gutterBottom
        sx={{
          fontWeight: 700,
          fontSize: "0.75rem",
          display: "flex",
          justifyContent: "center",
          letterSpacing: "0.1em",
          color: colors.white,
          marginBottom: 1,
        }}>
        {title}
      </Typography>
      <StyledPaper elevation={3}>
        {totalsOnly ? (
          <>
            <FullTeamScore isRed>{redPoints}</FullTeamScore>
            <FullTeamScore>{bluePoints}</FullTeamScore>
          </>
        ) : (
          <>
            <TeamScore isRed>{redPoints}</TeamScore>
            <ScoreBar>
              <WinningLine />
              {[...Array(totalCells)].map((_, index) => (
                <Cell
                  key={index}
                  isRed={index < redFilledCells}
                  isFilled={index < redFilledCells || index >= totalCells - blueFilledCells}
                  isMiddle={index === middleCellIndex}
                />
              ))}
            </ScoreBar>
            <TeamScore>{bluePoints}</TeamScore>
          </>
        )}
      </StyledPaper>
    </Box>
  );
};

export default TournamentStatus;
