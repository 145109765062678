// dark mode
export const colors = {
  pureWhite: "#FFFFFF",
  lightGreen: "#499c94",
  halfLightGreen: "rgba(18, 169, 146, 0.75)",
  hoverlightGreen: "rgba(18, 169, 146, 0.2)",
  yellow: "rgba(231, 209, 84, 1)",
  yellowHalfOpacity: "rgba(231, 209, 84, 0.2)",
  hoverYellow: "rgba(231, 209, 84, 0.5)",
  darkGreen: "rgba(29, 80, 72, 1)",
  darkGreenHover: "rgba(29, 80, 72, 0.2)",
  orange: "#f06c3d",
  hoverOrange: "rgba(252, 76, 2, 0.2)",
  red: "#cd2530",
  // red:"rgba(170, 24, 28, 1)",
  darkRed: "#8e1e24",
  // red: "#ff2c2c",
  // red: "rgba(200, 20, 20, 1)",

  hoverRed: "rgba(200, 20, 20, 0.2)",
  white: "rgba(193, 215, 204, 1)",
  purple: "rgba(153, 102, 255, 1)",
  hoverPurple: "rgba(153, 102, 255, 0.2)",
  teal: "rgba(0, 213, 208, 1)",
  halfTeal: "rgba(0, 213, 208, 0.5)",
  hoverTeal: "rgba(0, 213, 208, 0.2)",
  pink: "rgba(231, 93, 111,1)",
  hoverPink: "rgba(231, 93, 111,0.2)",
  lightGrey: "rgba(229, 229, 229,1)",
  hoverlightGrey: "rgba(229, 229, 229,0.2)",
  darkGrey: "#1a1a1a",
  hoverdarkGrey: "rgba(68, 68, 68,0.2)",
  lightBlue: "rgba(0, 60, 130, 1)",
  darkLightBlue: "#023067",
  hoverlightBlue: "rgba(0, 60, 130, 0.2)",
  darkBlue: "rgba(3, 31, 53,1)",
  hoverdarkBlue: "rgba(3, 31, 53,0.2)",
  black: "rgb(0, 0, 0)",
  darkTeal: "rgba(0, 126, 134,0.8)",
  hoverdarkTeal: "rgba(0, 126, 134,0.2)",
  brightGreen: "rgba(5, 182, 113,1)",
  hoverbrightGreen: "rgba(5, 182, 113,0.2)",
  tableGrey: "#f5f5f5",
  scoreCardBlue: "#132d46",
  scoreCardHover: "#10484f",
  bogeyGreen: "#2e706a",
  loadingGreen: "#01c38e",
  backgroundGreen: "#1d3543",
};

// light mode
// export const colors = {
//   pureWhite: "#FFFFFF",
//   lightGreen: "#499c94",
//   halfLightGreen: "rgba(18, 169, 146, 0.75)",
//   hoverlightGreen: "rgba(18, 169, 146, 0.2)",
//   yellow: "rgba(231, 209, 84, 1)",
//   yellowHalfOpacity: "rgba(231, 209, 84, 0.2)",
//   hoverYellow: "rgba(231, 209, 84, 0.5)",
//   darkGreen: "rgba(29, 80, 72, 1)",
//   darkGreenHover: "rgba(29, 80, 72, 0.2)",
//   orange: "#f06c3d",
//   hoverOrange: "rgba(252, 76, 2, 0.2)",
//   red:"#aa181c",
//   // red: "#ff2c2c",
//   // red: "rgba(200, 20, 20, 1)",

//   hoverRed: "rgba(200, 20, 20, 0.2)",
//   white: "rgba(3, 31, 53,1)",
//   purple: "rgba(153, 102, 255, 1)",
//   hoverPurple: "rgba(153, 102, 255, 0.2)",
//   teal: "rgba(0, 213, 208, 1)",
//   halfTeal: "rgba(0, 213, 208, 0.5)",
//   hoverTeal: "rgba(0, 213, 208, 0.2)",
//   pink:"rgba(231, 93, 111,1)",
//   hoverPink:"rgba(231, 93, 111,0.2)",
//   lightGrey: "rgba(229, 229, 229,1)",
//   hoverlightGrey: "rgba(229, 229, 229,0.2)",
//   darkGrey: "#1a1a1a",
//   hoverdarkGrey: "rgba(68, 68, 68,0.2)",
//   lightBlue: "rgba(0, 60, 130, 1)",
//   hoverlightBlue: "rgba(0, 60, 130, 0.2)",
//   darkBlue:"#c1d7cc",
//   hoverdarkBlue:"rgba(3, 31, 53,0.2)",
//   black:"rgb(0, 0, 0)",
//   darkTeal: "rgba(0, 126, 134,0.8)",
//   hoverdarkTeal: "rgba(0, 126, 134,0.2)",
//   brightGreen: "rgba(5, 182, 113,1)",
//   hoverbrightGreen: "rgba(5, 182, 113,0.2)",
//   tableGrey: '#f5f5f5',
//   scoreCardBlue: '#ffffff',
//   scoreCardHover: '#10484f',
//   bogeyGreen: '#2e706a',
//   loadingGreen: '#01c38e',
//   backgroundGreen: '#1d3543'
// };
