// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playerPage {
    margin-top: 2.5%;
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.gridContainer {
  min-width:320px;
}

.pageHeaderRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
}


@media (max-width: 800px) {
    .playerPage {
      margin-top: 25px;
      margin-left:0px;
      margin-right:0px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      height:100%;
    }
  
    .pageHeaderRow {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;
    }
  
  
    .MuiPaper-root {
      left: 0 !important;  /* Override the left positioning */
    }   
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/PlayerPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;;;AAGA;IACI;MACE,gBAAgB;MAChB,eAAe;MACf,gBAAgB;MAChB,mBAAmB;MACnB,aAAa;MACb,sBAAsB;MACtB,WAAW;IACb;;IAEA;MACE,aAAa;MACb,sBAAsB;MACtB,mBAAmB;MACnB,uBAAuB;MACvB,SAAS;IACX;;;IAGA;MACE,kBAAkB,GAAG,kCAAkC;IACzD;EACF","sourcesContent":[".playerPage {\n    margin-top: 2.5%;\n    margin-right: 10%;\n    margin-left: 10%;\n    margin-bottom: 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n}\n\n.gridContainer {\n  min-width:320px;\n}\n\n.pageHeaderRow {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    gap: 50px;\n}\n\n\n@media (max-width: 800px) {\n    .playerPage {\n      margin-top: 25px;\n      margin-left:0px;\n      margin-right:0px;\n      margin-bottom: 25px;\n      display: flex;\n      flex-direction: column;\n      height:100%;\n    }\n  \n    .pageHeaderRow {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      gap: 50px;\n    }\n  \n  \n    .MuiPaper-root {\n      left: 0 !important;  /* Override the left positioning */\n    }   \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
